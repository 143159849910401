
import { Component, Vue } from 'nuxt-property-decorator'
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'UserState'
})
export default class UserState extends Vue {
  public get size(): number {
    // @ts-ignore
    if (this.$vuetify.breakpoint.smAndUp) {
      return 48;
    } else {
      return 48 // Todo add correct sizing
    }
  }

  public get user(): any {
    return this.$store.state.user;
  }

  public get userName(): string {
    return this.user?.fullName ?? '';
  }

  public get initials(): string {
    return this.user?.email?.charAt(0).toUpperCase() ?? '';
  }

  public get email(): string {
    return this.user?.email;
  }

  public get redirectionTarget(): string {
    return this.user?.role?.name ?? '';
  }
}
