
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component({
	name: 'SubscriptionStatus'
})
export default class SubscriptionStatus extends Vue {
	@Prop({ required: false, default: false }) readonly full!: boolean;
	@Prop({ required: false, default: false }) readonly short!: boolean;

	public get now(): number {
		return Date.now();
	}

	public get endDate(): Date | null {
		return this.$store.state.user?.packageEndDate ?? null;
	}

	public get packageIsValid(): boolean {
		if (!this.endDate) return false;
		return new Date(this.endDate)?.getTime() >= this.now && this.$store.state.user?.boughtPackage;
	}
}
