import Axios from 'axios';
import groupBy from 'lodash.groupby';
import { QueryBuilder } from '~/plugins/QueryBuilder';

export const state = () => ({
  lists: [],
  list: {}
});

export const getters = {
  offerByOrganizationTitle(state: any) {
    return groupBy(state.lists, '')
  },
  contactIds(state: any) {
    const ids: string[] = [];

    state.lists.forEach((list: any) => {
      list.offers.forEach((offer: any) => {
        offer.contacts.forEach((contact: any) => {
          ids.push(contact?.id);
        })
      })
    });

    return ids;
  },
  contacts(state: any) {
    const contacts: string[] = [];

    state.lists.forEach((list: any) => {
      list.offers.forEach((offer: any) => {
        offer.contacts.forEach((contact: any) => {
          if (!contacts.map((c: any) => c.id)?.includes(contact.id)) {
            contact.organization = offer.organization;
            contacts.push(contact);
          }
        })
      })
    });

    return [...new Set(contacts)];
  }
}

export const mutations = {
  SET_LISTS(state: any, lists: any[]) {
    state.lists = lists;
  },
  SET_LIST(state: any, list: any) {
    state.list = list;
  },
  UPDATE_LIST_DATA(state: any, list: any) {
    const index = state.lists.findIndex(l => l.id === list.id);
    state.lists.splice(index, 1, list)
  }
}

export const actions = {
  async fetchLists(context: any, archived: boolean = false) {
    const lists = await Axios({
      method: 'GET',
      url: `${process.env.apiUrl}/lists?archived=${archived}`
    }).then(response => response.data)
      .catch(error => { throw error });

    await context.commit('SET_LISTS', lists);
  },
  async fetchList(context: any, data: any) {
    const { listId } = data;
    const list = await Axios({
      method: 'GET',
      url: `${process.env.apiUrl}/lists/${listId}`
    }).then(response => response.data)
      .catch(error => { throw error });

    await context.commit('SET_LIST', list);
  },
  async updateListName(context: any, data: any) {
    const { title, id } = data;
    const updated = await Axios({
      method: 'PATCH',
      url: `${process.env.apiUrl}/lists/${id}`,
      data: { title: title }
    }).then(response => response.data)
      .catch(error => { throw error });

    await context.commit('SET_LIST', updated);
  },
  async archiveList(context: any, data: any) {
    const { archived, id } = data;
    const list = await Axios({
      method: 'PATCH',
      url: `${process.env.apiUrl}/lists/${id}/archive`,
      data: {
        archived: archived
      }
    }).then(response => response.data)
      .catch(error => { throw error });

    await context.dispatch('fetchLists');
  },
  async internalSearch(context: any, _data: any) {
    const queryString = QueryBuilder.query(_data.query)
    const results = await Axios({
      method: 'POST',
      url: `${process.env.apiUrl}/internal-search/?${queryString}`,
      data: {
        ids: _data.ids
      }
    }).then(response => response.data)
      .catch(error => { throw error });

    const data = groupBy(results, 'organization.title');
    return data;
  },

  async createNewList(context: any, list: any) {
    const offers: string[] = [];

    Object.keys(list.offers)?.forEach((key: string) => {
      list.offers[key]?.forEach((offer: any) => {
        offers.push(offer.id);
      });
    });

    const data = {
      ...list,
      offers: offers
    };
    delete data.id;
    const _list = await Axios({
      method: 'POST',
      url: `${process.env.apiUrl}/lists`,
      data: data
    }).then(response => response.data)
      .catch(error => { throw error })

    return _list;
  },

  async sanityCheck(context: any, listId: string) {
    const currentData = await Axios({
      method: 'POST',
      url: `${process.env.apiUrl}/lists/sanity-check`,
      data: { listId: listId }
    }).then(response => response.data)
      .catch(error => { throw error });

    await context.commit('UPDATE_LIST_DATA', currentData.list);

    return currentData;
  },

  async exportListFromLenderPlatform(context: any, input: { ids: string[]; query: any }) {
    const listId = await Axios({
      method: 'POST',
      url: `${process.env.apiUrl}/export-list`,
      data: input
    }).then(response => response.data)
      .catch(err => { throw err });

      return listId;
  }
}
