export interface SwagState {
  spotifyScript: HTMLElement & { src?: string } | null;
  swagModeActivated: boolean;
  isHidden?: boolean;
  passCookies: boolean;
}

export const state = (): SwagState => ({
  spotifyScript: null,
  swagModeActivated: false,
  isHidden: false,
  passCookies: false
});

export const mutations = {
  ACTIVATE_SWAG_MODE(state: SwagState, payload: boolean) {
    state.swagModeActivated = payload;

    if (!state.swagModeActivated) {
      [
        'onSpotifyIframeApiReady',
        'SpotifyIframeConfig',
        'SpotifyIframeApi'
      ].forEach(key => (window as any)[key] = undefined);
    }
  },

  SET_SPOTIFY_SCRIPT(state: SwagState, payload: HTMLElement & { src?: string }) {
    state.spotifyScript = payload;
  },

  HIDE(state: SwagState) {
    state.isHidden = true;
  },

  PASS(state: SwagState) {
    state.passCookies = true;
  }
}

export const actions = {
  toggle({ commit }, val: boolean) {
    commit('ACTIVATE_SWAG_MODE', val);
  },

  setScript({ commit }, script: HTMLElement & { src?: string } | null) {
    commit('SET_SPOTIFY_SCRIPT', script);
  },

  hide({ commit }) {
    commit('HIDE');
  },

  passCookies({commit}) {
    commit('PASS');
  }
}