import Axios from 'axios';
import Vue from 'vue';
import VueNotification from '@mathieustan/vue-notification';

Vue.use(VueNotification);

const errorInterceptor = (error) => {
  return () => {}
}

Axios.interceptors.response.use(undefined, (error) => {
  error.handleGlobally = errorInterceptor(error)();

  return Promise.reject(error);
})
