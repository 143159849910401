
import { Component, Vue } from 'nuxt-property-decorator'

@Component({
  name: 'InvoiceLayout'
})
export default class InvoiceLayout extends Vue {
  mounted() {
    this.$store.dispatch("verifyUser");
  }
}
