import Vue from 'vue';
const moment = require('moment')

const offerTypes = {
  HOTEL: 'Hotel',
  RETIREMENT_HOME: 'Pflegeheim',
  SINGLE_HOME: 'Einfamilienhaus'
};

Vue.filter('parsePrice', (value: string, { short = true } = {}): string => {
  const _value = Number.parseFloat(value);
  const formatted = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(_value);

  if (!!short && _value >= 1000000) return `${(_value / 1000000)?.toFixed(2)?.replace('.', ',')} Mio. €`;
  return formatted;
});

Vue.filter('parseShortPrice', (value: string, { short = true } = {}): string => {
  const _value = Number.parseFloat(value);
  if (!_value) return 'k.A.';
  const formatted = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(_value);

  return `${(_value / 1000000)?.toFixed(2)?.replace('.', ',')} Mio. €`;
});

Vue.filter('parseOfferTypes', (value: any[]): string => {
  return value.map((type: any) => {
    return offerTypes[type.key];
  }).join(', ')
});

Vue.filter('parseDate', (value: Date) => {
  return moment(value).format('DD.MM.YYYY');
});

Vue.filter('parseDateTime', (value: Date) => {
  return `${moment(value).format('DD.MM.YYYY - HH:mm')} (GMT)`;
});

Vue.filter('parseContactName', (value: any) => {
  const fileds = [
    value.nameTitle ?? '',
    value.firstName ?? '',
    value.lastName ?? ''
  ];

  return fileds.filter((field: string) => !!field).join(' ');
});

Vue.filter('parseAddress', (value: any, line: number) => {
  //Guard clause
  if (!(!!value)) return 'k.A.';

  if (line === 1) return `${value.street || 'k.A'} ${value.streetNumber || 'k.A'}`;
  if (line === 2) return `${value.zipCode || 'k.A'}, ${value.city || 'k.A'} ${value.country || 'k.A.'}`;

  else return `${value.zipCode || 'k.A'}, ${value.city || 'k.A'} ${value.country || 'k.A.'}`;
});

Vue.filter('parseLocation', (value: string): string => {
  const fields = value?.split('_')?.slice(0, 2);
  return fields?.join(', ') ?? '-';
})

Vue.filter('parseProjectCompletion', (value: number): number => {
  if (!value) return 0;
  return +(value * 100).toFixed(0);
})

Vue.filter('isPremium', (value: any | null): boolean => {
  if (!value || !value.email) return false;
  return !!value.boughtPackage
    && new Date(value.packageDate).getTime() <= Date.now()
    && new Date(value.packageEndDate).getTime() >= Date.now()
})

Vue.filter('isSignedIn', (value: any | null | undefined): boolean => {
  if (!value || !value?.email) return false;
  return true;
})
