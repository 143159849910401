
import { Component, Vue } from "nuxt-property-decorator";
import Navbar from "../components/global/navbar.vue";
import Footer from "../components/global/footer.vue";
import AnnouncementBanner from "../components/frontpage/announcement-banner.vue";

@Component({
	name: "Default",
	components: {
		navbar: Navbar,
		footerComponent: Footer,
		announcementBanner: AnnouncementBanner
	},
})
export default class Default extends Vue {
	public completelyMounted: boolean = false;
	public warningDialogOpen: boolean = false;
	public showSubvention: boolean = true;

	head() {
		return {
			htmlAttrs: {
				lang: this.$i18n?.locale ?? 'de'
			},
			link: [
				{ rel: 'canonical', href: `https://www.finlist.de${this.$route.path}`, hid: 'canonical' }
			],
			meta: [
				{ property: 'og:type', content: 'website', hid: 'og:type' },
				{ property: 'og:url', content: `https://www.finlist.de${this.$route.path}`, hid: 'og:url' }
			]
		}
	}
	mounted() {
		if (this.$route?.path?.includes('/lenders')) this.showSubvention = false;
		this.completelyMounted = true;
		this.$store.dispatch("verifyUser");
		// this.$store
		//   .dispatch("search/openLastSearchModal")
		//   .then((value: boolean) => {
		//     if (!!value && !this.$route.path.includes('/location')) {
		//       //@ts-ignore
		//       this.$refs['lastSearchModal'].openModal();
		//     }
		//   });
	}

	public get isDesktop(): boolean {
		//@ts-ignore
		return this.$vuetify?.breakpoint.smAndUp;
	}

	public get isUser(): boolean {
		return this.$store.state?.user?.email?.length;
	}
}
