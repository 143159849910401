import Axios from "axios";
import { QueryBuilder } from "../plugins/QueryBuilder";

export const state = () => ({
  report: {}
})

export const mutations = {
  SET_REPORT(state: any, data: any) {
    state.report = data;
  }
}

export const actions = {
  async createReport(context: any, { location, usageType, email }) {
    const reportData = await Axios({
      method: 'POST',
      url: `${process.env.apiUrlV2}/market-overview/report/new`,
      data: {
        location,
        usageType,
        email
      }
    })
      .then(response => response.data)
      .catch(e => { throw e });

      return reportData;
  },

  async fetchReport({ commit }: any, reportId: string) {
    const reportData = await Axios({
      method: 'GET',
      url: `${process.env.apiUrlV2}/market-overview/report/${reportId}`
    })
      .then(response => {
        if (!response) throw new Error('Something went wrong');
        commit('SET_REPORT', response.data);
        return response.data;
      })
      .catch(e => { throw e });

    return reportData;
  },

  async fetchReportByQuery({ commit }: any, query: string) {
    const _query = QueryBuilder.query(query);
    const reportData = await Axios({
      method: 'GET',
      url: `${process.env.apiUrlV2}/market-overview/report?${_query}`
    })
      .then(response => {
        if (!response) throw new Error('Something went wrong');
        commit('SET_REPORT', response.data);
        return response.data;
      })
      .catch(e => { throw e });

    return reportData;
  }
}