import Axios from "axios";

export const state = () => ({
  matchingLenders: [],
  mismatch: [],
  recommendations: {},
  market: {},
  triggersActivated: true,
  projectMarket: { data: {} }
});

export const mutations = {
  SET_OPTIMIZATION(state: any, payload: any) {
    state.matchingLenders = payload.matchingLenders ?? [];
    state.mismatch = payload.mismatch ?? [];
  },

  SET_RECOMMENDATIONS(state: any, payload: any) {
    state.recommendations = payload;
  },

  SET_MARKET(state: any, payload: any) {
    state.market = payload;
  },

  UPDATE_TRIGGER_SETTINGS(state: any, value: boolean) {
    state.triggersActivated = value;
  },
  SET_PROJECT_MARKET(state: any, value: any) {
    const defaultObj = {
      "data": {
        "count": 0,
        "types": {
          "Bank": 0,
          "Fonds": 0
        },
        "amounts": {
          "min": 0,
          "max": 0
        },
        "interestRates": {
          "min": 0,
          "max": 0
        },
        "loanValues": {
          "ltv": {
            "min": 0,
            "max": 0
          },
          "ltc": {
            "min": 0,
            "max": 0
          }
        },
        "durations": {
          "min": 0,
          "max": 400
        },
        "usageTypes": [],
        "loans": {
          "mezzanine": 0,
          "seniorLoan": 0,
          "wholeLoan": 0
        }
      }
    }
    state.projectMarket = Object.assign(defaultObj, value);
  }
}

export const actions = {
  async fetchOptimization({ commit, state }: any, projectId: string) {
    const optimization = await Axios({
      method: 'GET',
      url: `${process.env.apiUrlV2}/trigger/optimization/${projectId}`
    })
      .then(response => {
        commit('SET_OPTIMIZATION', response.data)
        return response.data;
      })
      .catch(e => { throw e });

    return optimization;
  },

  async fetchRecommendations({ commit, state, dispatch }: any, projectId: string) {
    const optimization = await Axios({
      method: 'GET',
      url: `${process.env.apiUrlV2}/trigger/optimization/recommendation/${projectId}`
    })
      .then(response => {
        commit('SET_RECOMMENDATIONS', response.data);
        return response.data;
      })
      .catch(e => { console.log(e) })
      .finally(() => {
        dispatch('queryMarketByProjectId', projectId)
      })

    return optimization;
  },

  async queryMarket({ commit }: any, query: any) {
    const market = await Axios({
      method: 'GET',
      url: `${process.env.apiUrlV2}/market-overview?offerTypes=${query.offerTypes}&location=${query.location}`
    })
      .then(response => {
        commit('SET_MARKET', response.data);
        return response.data;
      })
      .catch(e => { return });
  },

  async queryMarketByProjectId({ commit }: any, projectId: string) {
    const market = await Axios({
      method: 'GET',
      url: `${process.env.apiUrlV2}/market-overview/projects/${projectId}`
    })
      .then(response => {
        commit('SET_PROJECT_MARKET', response.data);
        return response.data;
      })
      .catch(e => { console.log(e) });

    return market;
  }
}