export const state = () => ({
  offerTypes: [
    { key: 'office', text: 'Büro' },
    { key: 'coWorking', text: 'Co-Working' },
    { key: 'living', text: 'Wohnen' },
    { key: 'logistics', text: 'Logistik' },
    { key: 'productionFacilities', text: 'Produktionsstätten' },
    { key: 'lightProduction', text: 'Montage' },
    { key: 'retail', text: 'Einzelhandel' },
    { key: 'holidayHotel', text: 'Ferien-Hotel' },
    { key: 'businessHotel', text: 'Business-Hotel' },
    { key: 'specialProperty', text: 'Spezialimmobilie' },
    { key: 'nursingHome', text: 'Pflegeimmobilie' },
    { key: 'labs', text: 'Labore / Health Care' },
    { key: 'industryKitchen', text: 'Industrieküche' },
    { key: 'operatorModels', text: 'Betreiberimmobilie' },
    { key: 'microApartments', text: 'Micro-Apartments' },
    { key: 'studentHousing', text: 'Studenten-Wohnheim' },
    { key: 'modularConstruction', text: 'Modularbau' },
    // { key: 'iternative', text: 'Alternative Baustoffe' },
    // { key: 'energyEfficient', text: 'Energieeffizientes Bauen' },
  ],
  financingPlan: [
    { key: 'globalSale', text: 'Verkauf' },
    {
      key: 'apartmentSale', text: 'Apartmentverkauf', restrictions: {
        typeOf: ['purchase'],
        offerTypes: ['office', 'coWorking', 'logistics', 'productionFacilities', 'lightProduction', 'retail', 'holidayHotel', 'businessHotel', 'specialProperty', 'labs', 'industryKitchen']
      }
    },
    {
      key: 'developToHold', text: 'Eigenbetrieb'
    },
    { key: 'partitioning', text: 'Aufteilung', restrictions: {
      offerTypes: ['office', 'coWorking', 'logistics', 'productionFacilities', 'lightProduction', 'retail', 'holidayHotel', 'businessHotel', 'specialProperty', 'labs', 'industryKitchen']
    } },
    {
      key: 'buyAndHold', text: 'Vermietung', restrictions: {
        typeOf: ['projectDevelopment']
      }
    },
  ],
  requiredCapital: [
    { key: 'seniorLoan', text: 'Fremdkapital' },
    { key: 'mezzanine', text: 'Mezzanine' },
    { key: 'wholeLoan', text: 'Whole Loan' },
    // { key: 'jointVenture', text: 'Co-Equity' }
  ],
  typeOf: [
    { key: 'purchase', text: 'Ankauf / Bestandsimmobilie' },
    { key: 'projectDevelopment', text: 'Projektentwicklung' },
    { key: 'landPurchase', text: 'Grundstücksankauf' },
    { key: 'portfolioFinancing', text: 'Portfolio-Finanzierung' },
    // { key: 'crossBorderFinancing', text: 'Cross-Border-Finanzierung' },
    // { key: 'multiCurrencyFinancing', text: 'Multi-Currency-Finanzierung' },
    // { key: 'nonRecourseFinancing', text: 'Non-Recourse-Finanzierung' },
    // { key: 'bridgeFinancing', text: 'Bridgefinanzierung' },
    { key: 'followUpFinancing', text: 'Anschluss- / Umfinanzierung' },
    { key: 'modernisation', text: 'Modernisierung' }
  ],
  financingStructure: [
    { key: 'withOwnAppraisal', text: 'Mit eigenem Gutachten' },
    { key: 'hypzert', text: 'Hypzert' },
    { key: 'shareDeal', text: 'Share Deal' },
    { key: 'assetDeal', text: 'Asset Deal' },
    { key: 'clubDeal', text: 'Club Deal' },
    { key: 'callOptions', text: 'Call Options' },
  ],
  securitization: [
    { key: 'debt', text: 'Grundschuld' },
    { key: 'personalGuarantee', text: 'Persönliche Bürgschaft' },
    { key: 'letterOfComfort', text: 'Patronatserklärung' },
    { key: 'crossLiability', text: 'Kreuzhaftung' },
    { key: 'exclusiveDeal', text: 'Exklusiv-Deal' },
    { key: 'fixedShare', text: 'Fixbeteiligung' },
    { key: 'profitShare', text: 'Gewinnbeteiligung' },
    { key: 'sharePledge', text: 'Verpfändung der Geschäftsanteile' }
  ],
  permissionStatus: [
    { key: 'withoutBuildingPermission', text: 'Keine Baugenehmigung' },
    { key: 'constructionPlan', text: 'Bauplan liegt vor' },
    { key: 'preRequest', text: 'Bauvoranfrage eingereicht' },
    { key: 'preliminaryPermit', text: 'Positiver Bauvorbescheid' },
    {
      key: 'withBuildingPermission', text: 'Baugenehmigung', restrictions: {
        typeOf: ['purchase']
      }
    },
    {
      key: 'withBuildingRight', text: 'Baurecht erteilt', restrictions: {
        typeOf: ['projectDevelopment']
      }
    },
    {key: 'permissionNotRelevant', text: 'Nicht benötigt'}
  ],
  countries: [
    { key: 'de', text: 'Deutschland' },
    { key: 'at', text: 'Österreich' },
    { key: 'ch', text: 'Schweiz' },
  ],
  administration: {
    de: [
      { key: 'Baden-Württemberg', text: 'Baden-Württemberg' },
      { key: 'Bayern', text: 'Bayern' },
      { key: 'Berlin', text: 'Berlin' },
      { key: 'Brandenburg', text: 'Brandenburg' },
      { key: 'Bremen', text: 'Bremen' },
      { key: 'Hamburg', text: 'Hamburg' },
      { key: 'Hessen', text: 'Hessen' },
      { key: 'Mecklenburg-Vorpommern', text: 'Mecklenburg-Vorpommern' },
      { key: 'Niedersachsen', text: 'Niedersachsen' },
      { key: 'Nordrhein-Westfalen', text: 'Nordrhein-Westfalen' },
      { key: 'Rheinland-Pfalz', text: 'Rheinland-Pfalz' },
      { key: 'Saarland', text: 'Saarland' },
      { key: 'Sachsen', text: 'Sachsen' },
      { key: 'Sachsen-Anhalt', text: 'Sachsen-Anhalt' },
      { key: 'Schleswig-Holstein', text: 'Schleswig-Holstein' },
      { key: 'Thüringen', text: 'Thüringen' },
    ]
  }
})
