export class QueryBuilder {
  constructor() { }

  static query(params: any): string {
    // const paramsArray: string[] = [];
    // const keys: string[] = Object.keys(params);

    // keys.forEach((key: string) => {
    //   if (!!params[key]) {
    //     if (key === 'filters') {
    //       paramsArray.push(`filters=${params[key]}`);
    //     } else if (typeof params[key] === 'string') {
    //       paramsArray.push(`${key}=${params[key]}`);
    //     } else if (!!params[key].value) {
    //       if (typeof params[key].value === 'string') {
    //         paramsArray.push(`${key}${params[key].operator ? params[key].operator : ''}=${params[key].value}`);
    //       } else if (Array.isArray(params[key].value)) {
    //         params[key].value.forEach((value) => {
    //           paramsArray.push(`${key}${params[key].operator ? params[key].operator : ''}=${value}`)
    //         })
    //       }
    //     }
    //   }
    // });
    // const paramsString: string = paramsArray.join('&') || '';
    // return paramsString;
    return Object.keys(params)
      .filter((key: string) => {
        if (key === 'consortium') return true;
        return !!params[key];
      })
      .map((key: string) => {
        return `${key}=${params[key]}`;
      }).join('&');
  }
}
