export class StorageSync {
  private vm: any;
  public hasSeenModal: boolean = false;
  constructor(vm: any, hasSeenModal: boolean) {
    this.vm = vm;
    this.hasSeenModal = hasSeenModal;
  }

  public get storageExists(): boolean {
    return typeof Storage !== 'undefined';
  }

  public get shouldShowModal(): boolean {
    return this.storageExists && !!this.lastSearch && !!this.lastSearch.includes('amount');
  }

  public get lastSearch(): any {
    if (this.storageExists) {
      return localStorage?.getItem('last_uncompleted_search');
    } else {
      return {}
    }
  };

  public setLastUncompletedSearch(search: any): void {
      const userSearched: boolean = !!Object.keys(search)?.some((key: string) => {
        return !!search[key] && key !== 'results';
      });

      if (this.storageExists && userSearched) {
        localStorage.setItem('last_uncompleted_search', JSON.stringify(search));
      }
  }

  public deleteLastSearchWhenCompleted(): void {
    if (this.storageExists) {
      localStorage.removeItem('last_uncompleted_search');
    }
  }

  public showModal(): void {}
}
