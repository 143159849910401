import Axios from "axios";
import { IProject } from "~/model/project";

export const state = () => ({
  project: null
});

export const mutations = {
  SET_PROJECT(state: any, project: IProject) {
    state.project = project;
  }
}

export const actions = {
  async fetchProject({ commit }, data: { email: string; token: string; id: string; }) {
    const project = await Axios({
      method: 'POST',
      url: `${process.env.apiUrl}/projects/public?id=${data.id}&token=${data.token}`,
      data: {
        email: data.email
      }
    }).then(response => response.data)
      .catch(err => { throw err });

    if (project === 'ACCESS_DENIED') throw 'Access denied';

    commit('SET_PROJECT', project);
  },

  async increaseViewCount(context: any, projectId: string | number) {
    const increasedCount = await Axios({
      method: 'POST',
      url: `${process.env.apiUrl}/projects/${projectId}/views`,
      data: null
    }).then(response => response.data)
      .catch(err => { console.error(err) });

    return;
  },

  async cancelProject(ctx: any, payload: any) {
    const { project, creditor, customer, cancellationReason } = payload;
    const projectCancelled = await Axios({
      method: 'POST',
      url: `${process.env.apiUrl}/projectStatus/cancel/${project._id}`,
      data: { creditor, title: project.title, customer, cancellationReason }
    }).then(response => response.data)
      .catch(e => { throw e; });

      return await projectCancelled;
  },

  async acceptProject(ctx: any, payload: any) {
    const { project, creditor, customer, message } = payload;
    const projectAccepted = await Axios({
      method: 'POST',
      url: `${process.env.apiUrl}/projectStatus/accept/${project._id}`,
      data: { creditor, title: project.title, customer, message }
    }).then(response => response.data)
      .catch(e => { throw e; });

      return await projectAccepted;
  },

  async sendOpenNotification(ctx: any, payload: any) { },
}
