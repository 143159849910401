import cookie from 'cookie';
export const whiteList = ['/imprint', '/privacy', '/agb', '/registration-success', '/', '/search']

export default function ({ req, redirect, route }) {
  try {
    let tokenExists: boolean = false;

    if (!!process.server && !!req?.headers?.cookie) tokenExists = cookie.parse(req?.headers?.cookie)?.finlistAuthToken;
    if (!!process.browser && typeof document !== 'undefined' && !!document.cookie) tokenExists = cookie.parse(document?.cookie)?.finlistAuthToken;

    if (!tokenExists && route.path.includes('/customer') && !route.path.includes('/customer-registration')) redirect('/login');
  } catch (e) {
    return;
  }
}
