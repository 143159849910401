import Axios from 'axios';

export const state = () => ({
  offers: [],
  offer: {}
});

export const mutations = {
  SET_OFFERS(state: any, payload: any[]) {
    state.offers = payload;
  },
  SET_OFFER(state: any, payload: any) {
    state.offer = payload;
  }
};

export const actions = {
  async fetchOffers(context: any) {
    const offers = await Axios({
      method: 'GET',
      url: `${process.env.apiUrl}/offers`
    }).then(response => response.data)
      .catch(error => { throw error });

    await context.commit('SET_OFFERS', offers);
  }
};
