import Axios from 'axios';
import cookie from 'cookie';
import { migrationFlow } from '../plugins/firebase/auth-methods';

export const state = () => ({
  user: null,
  blogPosts: [],
  singlePost: {},
  releases: [],
  testimonials: [
    {
      name: 'Liza Marie Kil',
      company: 'COPRO Projektentwicklung',
      text: 'Der Anfrageprozess von FinList macht es uns möglich, eine Vielzahl von Kreditgebern gleichzeitig zu kontaktieren und so viel Zeit zu sparen. Ebenso haben wir den Vorteil, zügig zum Objekt und der Region passende Finanzierungspartner anzusprechen und neue Geschäftsbeziehungen aufbauen zu können.',
      short: 'LK'
    },
    {
      name: 'Ferdinand Guggenberger',
      company: 'Meine-Renditeimmobilien GmbH',
      text: 'Als Projektentwickler im Immobilienbereich sind wir auf verlässliche Finanzierungspartner angewiesen, die unsere Bau- und Sanierungsvorhaben begleiten. Die Kooperation mit Finlist hat uns viele Türen zu Banken und Finanzierern geöffnet, die zuvor entweder verschlossen oder gar nicht auf unserem Radar waren. Neben der Erweiterung des Netzwerks hat uns die Finlist-Plattform auch maßgeblich dabei unterstützt, die Kommunikation und Abwicklung mit den angeschlossenen Banken zu vereinfachen und zu optimieren.',
      short: 'FG'
    },
    {
      name: 'Oliver Riebartsch',
      company: 'Satori Transaction Advisory',
      text: 'Von FinList habe ich neue und unbekannte Mezzanine-Kontakte erhalten, obwohl ich recht tief vernetzt bin.',
      short: 'OR'
    },
    {
      name: 'Marvin Kirschner',
      company: 'Kirsche.Immo',
      text: 'Mit FinList habe ich noch am selben Tag schnell und unkompliziert Kreditgeber-Informationen für die Finanzierung eines Ärztehauses (Volumen 8.5 Mio) erhalten, obwohl es eine komplexe Anfragestruktur hat. Ich bin sehr überzeugt von dem FinList Konzept.',
      short: 'MK'
    },
    {
      name: 'Eugene K. Owusu',
      company: 'BWFD GmbH',
      text: 'Bei der gewerblichen Immobilienfinanzierung kommt es auf Schnelligkeit und einen verlässlichen Finanzierungspartner an. Die Plattform ist mit wenigen klicks sehr einfach zu bedienen und lieferte mir qualitativ hochwertige Daten. Ich bin mehr als begeistert und spare enorm viel Zeit für unsere Kunden.',
      short: 'EO'
    }
  ]
});

export const mutations = {
  SET_USER_FROM_VERIFICATION(state: any, user: any) {
    state.user = user;
  },
  DELETE_USER(state: any) {
    state.user = null;
  },
  SET_USER_FROM_LOGIN(state: any, user: any) {
    state.user = user?.user;
    document.cookie = `finlistAuthToken=${user?.jwt};path=/`;
    // Axios.defaults.headers['Authorization'] = `Bearer ${user?.jwt}`;
  },
  SET_BLOG_POSTS(state: any, payload: any[]) {
    state.blogPosts = payload;
  },
  SET_SINGLE_POST(state: any, payload: any) {
    state.singlePost = payload;
  },
  SET_PRESS_RELEASES(state: any, payload: any[]) {
    state.releases = payload.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  }
};

export const actions = {
  async logIn(context: any, data: any) {
    const user = await Axios({
      method: 'POST',
      url: `${process.env.apiUrl}/auth/local`,
      data: {
        identifier: data.email,
        password: data.password
      }
    }).then(response => {
      migrationFlow({
        email: data.email,
        password: data.password
      }).then(() => { })
        .catch(e => { console.log(e) });
      return response.data;
    })
      .catch(error => { context.dispatch('signOut') });

    await context.commit('SET_USER_FROM_LOGIN', user);

    return user;
  },
  async signOut(context: any) {
    try {
    } catch (e) { console.log(e) }
    if (typeof document !== 'undefined' && !!document) {
      document.cookie = "finlistAuthToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
    await context.commit('SET_USER_FROM_VERIFICATION', {});
    context.commit('DELETE_USER');
    return true;
  },

  async forgotPassword(context: any, email: string) {
    const success = await Axios({
      method: 'POST',
      url: `${process.env.apiUrl}/auth/forgot-password`,
      data: { email: email }
    }).then(response => response.data)
      .catch(error => { throw error })
  },

  async resetPassword(context: any, input: any) {
    const user = await Axios({
      method: 'POST',
      url: `${process.env.apiUrl}/auth/reset-password`,
      data: {
        code: input.code,
        password: input.password,
        passwordConfirmation: input.passwordConfirmation
      }
    }).then(response => response.data)
      .catch(error => { throw error });

    await context.commit('SET_USER_FROM_LOGIN', user);
    return user;
  },

  async signUpAsCustomer(context: any, data: any) {
    const user = await Axios({
      method: 'POST',
      url: `${process.env.apiUrl}/auth/local/register`,
      data: {
        username: `${data.fullName}_${Date.now()}`,
        fullName: data.fullName,
        referrer: `${document?.referrer ?? "no information"}`,
        company: {
          title: data.companyName,
          address: data.address
        },
        email: data.email,
        password: data.password,
        provider: 'local',
        role: process.env.CUSTOMER_ROLE_ID,
        phone: data.phone
      }
    }).then(response => {
      migrationFlow({
        email: data.email,
        password: data.password
      }).then(() => { })
        .catch(e => { console.log(e) });
      return response.data
    })
      .catch(error => { throw error });

    await context.commit('SET_USER_FROM_LOGIN', user);

    if (!!data.redirect) {
      await data.vm.$router.push(`/${await user?.user?.role?.name}`);
    }

    return context.dispatch('requestRegistrationMail', data.email);
  },
  async verifyUser(context: any) {
    if (!!document && !document?.cookie?.includes('finlistAuthToken')) return;
    const user = await Axios({
      method: 'GET',
      url: `${process.env.apiUrl}/users/me`,
    })
      .then(response => response.data)
      .catch(error => {
        return;
      });

    if (!!user) await context.commit('SET_USER_FROM_VERIFICATION', user);
    context.dispatch('getStatus');
  },

  async getStatus() {
    try {
      if (['development', 'local'].includes(process.env.NODE_ENV as string)) return;
      Axios({
        method: 'GET',
        url: `${process.env.apiUrlV2}/fl-status`
      })
    } catch (e) {
      return;
    }
  },

  async fetchBlogPosts(context: any) {
    const posts = await Axios({
      method: 'GET',
      url: `${process.env.apiUrl}/blog-posts`
    }).then(response => response.data)
      .catch(error => { throw error });

    if (!!posts) await context.commit('SET_BLOG_POSTS', posts);
  },
  async fetchSinglePost(context: any, slug: string) {
    const post = await Axios({
      method: 'GET',
      url: `${process.env.apiUrl}/blog-posts/${slug}`
    }).then(response => response.data)
      .catch(error => { throw error });

    await context.commit('SET_SINGLE_POST', post);
  },

  async requestRegistrationMail(context: any, mail: string) {
    try {
      const sent = await Axios({
        method: 'POST',
        url: `${process.env.apiUrl}/auxiliaries/registration-mail`,
        data: { mail: mail }
      }).then(response => response.data)
        .catch(error => { throw error });
    } catch (e) {
      return;
    }
  },

  async singUpAndSaveProject(context: any, data: any) {
    const user = await Axios({
      method: 'POST',
      url: `${process.env.apiUrl}/auth/local/register`,
      data: {
        username: `${data.fullName}_${Date.now()}`,
        fullName: data.fullName,
        company: {
          title: data.companyName,
          address: data.address
        },
        email: data.email,
        password: data.password,
        provider: 'local',
        role: process.env.CUSTOMER_ROLE_ID,
        phone: data.phone,
        project: data.project
      }
    }).then(response => response.data)
      .catch(error => { throw error });

    await context.commit('SET_USER_FROM_LOGIN', user);

    return context.dispatch('requestRegistrationMail', data.email);
  },

  async fetchReleases(context: any) {
    try {
      const releases = await Axios({
        method: 'GET',
        url: `${process.env.apiUrl}/press-releases`
      }).then(response => response.data);

      if (!!releases && !!releases?.length) {
        await context.commit('SET_PRESS_RELEASES', releases);
      }
    } catch (e) {
      console.log(e);
    }
  },

  async useExternalAuth({ commit, dispatch }, { provider = "", search = "" } = {}) {
    if (!search) throw 'No token found';

    const authResponse = await Axios({
      method: 'GET',
      url: `${process.env.apiUrl}/auth/${provider}/callback${search}`,
      headers: {
        'Authorization': ''
      }
    }).then(response => response.data);

    const userIsSet = new Promise((resolve, reject) => {
      commit('SET_USER_FROM_LOGIN', authResponse);
      setTimeout(() => {
        resolve(true);
      }, 200)
    });

    await userIsSet.then(() => {
      dispatch('verifyUser');
    });

    return userIsSet;

  },

  async checkEmailBeforeRegistration(context: any, email: string): Promise<{ result: boolean, message: string }> {


    const mailCheck = await Axios({
      method: 'POST',
      url: `${process.env.apiUrlV2}/auth/check-mail`,
      data: {
        email
      }
    }).then(res => res.data)
      .catch(e => { throw e });

    return mailCheck;
  },

  async subscribeToNewsletter(context: any, email: string) {
    const subscription = await Axios({
      method: 'POST',
      url: `${process.env.apiUrlV2}/auth/newsletter`,
      data: {
        email: email
      }
    })
      .then((res) => res)
      .catch((e) => { throw e });

    return subscription;
  }
};
