
import { Component, Vue } from 'nuxt-property-decorator'

@Component({
  name: 'CookieBanner'
})
export default class CookieBanner extends Vue {
  public acceptedCookies: boolean = true;

  public get showBanner(): boolean {
    return !this.acceptedCookies;
  }

  public acceptCookies(): void {
    if (typeof Storage !== 'undefined') {
      localStorage.setItem('cookiesAccepted', String(Date.now()));
      this.acceptedCookies = true;
    }
  }

  mounted() {
    if (typeof Storage !== 'undefined') {
      if (!!localStorage?.getItem('cookiesAccepted')) {
        this.acceptedCookies = true;
      } else {
        this.acceptedCookies = false;
      }
    }
  }
}
