import Axios from "axios";

export interface IActivity {
  title: string;
  body: string;
  createdAt: Date;
  type: 'PROJECT_CREATED' | 'EMAIL_OPENED' | 'PROJECT_OPENED' | 'PROJECT_DELETED' | 'INCOMING_QUESTION' | 'NEW_FEATURE';
  customer: any;
  project?: any;
  id: string;
}

export const state = (): { activities: IActivity[] } => ({
  activities: []
});

export const mutations = {
  SET_ACTIVITIES(state: { activities: IActivity[] }, payload: IActivity[]) {
    state.activities = payload;
  }
}

export const actions = {
  async fetchActivities({commit}): Promise<IActivity[]> {
    const activities = await Axios({
      method: 'GET',
      url: `${process.env.apiUrl}/activities`
    })
      .then((res) => {
        commit('SET_ACTIVITIES', res.data);
        return res.data;
      })
      .catch(e => { throw e });

      return activities;
  },

  async fetchActivitiesByProject(context, projectId: string): Promise<IActivity[]> {
    const activities = await Axios({
      method: 'GET',
      url: `${process.env.apiUrl}/activities/project/${projectId}`
    })
      .then((res) => {
        return res.data;
      })
      .catch(e => { throw e });

      return activities;
  },

  async deleteActivity({dispatch}, id: string): Promise<string> {
    const activity = await Axios({
      method: 'DELETE',
      url: `${process.env.apiUrl}/activities/${id}`
    })
      .then(async (res) => {
        await dispatch('fetchActivities')
        return res.data;
      })
      .catch(e => { throw e });

      return activity;
  }
}
