import Axios from "axios";

interface Organization {
  id?: string,
  title?: string;
  offers?: any[]
}

interface OrganizationDTO {
  organization: Organization;
  offers: any[];
  error?: boolean;
  generalChanges: any;
  offerChanges: any;
}

export const state = (): OrganizationDTO => ({
  organization: {},
  offers: [],
  error: false,
  generalChanges: {},
  offerChanges: {}
});

export const mutations = {
  SET_ORGANIZATION_AND_OFFERS(state: OrganizationDTO, payload: Organization) {
    state.organization = payload;
    state.offers = payload.offers ?? [];
  },
  SET_ERROR(state: OrganizationDTO, payload: boolean) {
    state.error = payload;
  },
  UPDATE_GENERAL_DATA(state: OrganizationDTO, payload: any) {
    state.generalChanges[payload.field] = payload.value;
  },

  UPDATE_OFFER_DATA(state: OrganizationDTO, payload: any) {
    state.offerChanges[payload.field] = payload.value;
  }
}

export const actions = {
  async fetchOrganization({ commit }, payload: any) {
    const organization = await Axios({
      method: 'GET',
      url: `${process.env.apiUrl}/organizations/${payload.id}?v=${payload.v}&email=${payload.email}`
    }).then(response => response.data);

    if (organization !== 'ACCESS DENIED') {
      commit('SET_ERROR', false);
      commit('SET_ORGANIZATION_AND_OFFERS', organization);
    }

    else if (organization === 'ACCESS DENIED') commit('SET_ERROR', true);


    return organization;
  },

  async updateData(context: any, payload: any) {
    const data = await Axios({
      method: 'POST',
      url: `${process.env.apiUrl}/organizations/data-update`,
      data: payload
    }).then(response => console.log(response))
      .catch(e => { throw e });

    return data;
  }
}
