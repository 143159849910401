import Axios from 'axios';
import { QueryBuilder } from '~/plugins/QueryBuilder';

export const state = () => ({
  lenders: []
});

export const mutations = {
  SET_LENDERS(state: any, payload: any[]) {
    state.lenders = payload;
  }
}

export const actions = {
  async fetchLenders({ commit }, params: any) {
    const query = QueryBuilder.query(params);
    const lenders = await Axios({
      method: 'GET',
      url: `${process.env.apiUrl}/lenders?${query}`
    })
      .then(response => {
        if (!!response && !!response?.data) {
          return response.data;
        }
        return { count: 0, offers: [] }
      })
      .catch(err => { throw err });

    await commit('SET_LENDERS', lenders);
    return lenders;
  },

  async fetchLendersV2({ commit }, params: any) {
    const query = QueryBuilder.query(params);
    const lenders = await Axios({
      method: 'GET',
      url: `${process.env.apiUrlV2}/lenders?${query}`
    })
      .then(response => {
        if (!!response && !!response?.data) {
          return response.data;
        }
        return { count: 0, offers: [] }
      })
      .catch(err => { throw err });

    await commit('SET_LENDERS', lenders);
    return lenders;
  }
}
