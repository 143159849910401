import Axios from "axios";

export const state = () => ({
  thinkImmoCustomer: {}
});

export const mutations = {
  SET_THINK_IMMO_CUSTOMER(state: any, payload: any[]) {
    state.thinkImmoCustomer = payload;
  }
}

export const actions = {
  async fetchThinkImmoCustomer({ commit }) {
    const thinkImmoCustomer = await Axios({
      method: 'GET',
      url: `${process.env.apiUrlV2}/thinkimmo`
    })
      .then(response => {
        const { data } = response;
        commit('SET_THINK_IMMO_CUSTOMER', data);
        return data;
      })
      .catch(e => { throw e });

    return thinkImmoCustomer;
  },

  async createThinkImmoCustomer({ dispatch }) {
    const thinkImmoCustomer = await Axios({
      method: 'POST',
      url: `${process.env.apiUrlV2}/thinkimmo/create-customer`
    })
      .then(response => {
        const { data } = response;
        return data;
      })
      .catch(e => { throw e });
    await dispatch('verifyUser', null, {root: true})
    await dispatch('fetchThinkImmoCustomer');
    return thinkImmoCustomer;
  },

  async createSearchAlert({ dispatch }, newAlert: any) {
    const savedAlert = await Axios({
      method: 'POST',
      url: `${process.env.apiUrlV2}/thinkimmo/add-alert`,
      data: {
        ...newAlert
      }
    })
      .then(response => {
        const { data } = response;
        return data;
      })
      .catch(e => { throw e });

    await dispatch('fetchThinkImmoCustomer');

    return savedAlert;
  },

  async deleteSearchAlert({ dispatch }, id: string) {
    return await Axios({
      method: 'DELETE',
      url: `${process.env.apiUrlV2}/thinkimmo/${id}`
    })
      .then(() => {
        return true;
      })
      .catch(e => { throw e; })
      .finally(() => {
        dispatch('fetchThinkImmoCustomer');
      })
  }
}