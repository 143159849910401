
import { Component, Vue } from "nuxt-property-decorator";
import Navbar from "../components/global/navbar.vue";
import Footer from "../components/global/footer.vue";
import CookieBanner from "~/components/global/cookiebanner.vue";

@Component({
	name: "Lenders",
	components: {
		navbar: Navbar,
		footerComponent: Footer,
		cookieBanner: CookieBanner
		// cookieLaw: () => {
		//   if (process.browser) {
		//     return import("vue-cookie-law");
		//   } else {
		//     return;
		//   }
		// },
	},
})
export default class Lenders extends Vue {
  head() {
		return {
			title: 'Kreditgeber Vergleich für gewerbliche Immoblienfinanzierung',
			meta: [
				{ property: 'og:title', hid: 'og:title', content: 'Kreditgeber Vergleich für gewerbliche Immoblienfinanzierung' },
				{ property: 'description', hid: 'description', content: 'Vergleichen Sie die Konditionen von über 140 Kreditgebern für gewerbliche Immobilienfinanzierung und erhalten Sie schneller und kostengünstiger Ihre Finanzierung.' },
        { property: 'og:description', hid: 'og:description', content: 'Vergleichen Sie die Konditionen von über 140 Kreditgebern für gewerbliche Immobilienfinanzierung und erhalten Sie schneller und kostengünstiger Ihre Finanzierung.' },
				{ hid: 'robots', name: 'robots', content: 'index, follow' }
			]
		}
	}

	public completelyMounted: boolean = false;

	mounted() {
		this.completelyMounted = true;
		this.$store.dispatch("verifyUser");
		// this.$store
		//   .dispatch("search/openLastSearchModal")
		//   .then((value: boolean) => {
		//     if (!!value && !this.$route.path.includes('/location')) {
		//       //@ts-ignore
		//       this.$refs['lastSearchModal'].openModal();
		//     }
		//   });
	}

	public get isDesktop(): boolean {
		//@ts-ignore
		return this.$vuetify?.breakpoint.smAndUp;
	}
}
