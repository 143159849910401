import Axios from "axios";
import { IProject } from "../model/project";
import { QueryBuilder } from "../plugins/QueryBuilder";

export const state = () => ({
  contacts: [],
  preview: {},
  project: {},
  currentQuery: {},
  email: ""
})

export const mutations = {
  SET_CONTACTS(state: any, payload: any[]) {
    state.contacts = payload;
  },

  SET_PREVIEW(state: any, payload: any) {
    state.preview = payload;
  },

  SET_PROJECT(state: any, payload: any) {
    state.project = payload;
  },

  SET_EMAIL(state: any, payload: string) {
    state.email = payload;
  },

  SET_CURRENT_QUERY(state: any, payload: any) {
    state.currentQuery = payload;
  }
}

export const actions = {
  async fetchContacts({ commit, dispatch }: any, project: IProject) {
    let query = await dispatch('projects/convertProjectToSearchQuery', project, { root: true });

    // Hotfix for loan value handling
    if (!!project.ltv || !!project.ltc) {
      query.loanValue = project.ltv ?? project.ltc;
    }

    // Hotfix for recourse strategies
    if (!!project.recourseStrategy) {
      query.recourseStrategy = project.recourseStrategy;
    }

    commit('SET_CURRENT_QUERY', query);
    query = QueryBuilder.query(query);

    const contacts = await Axios({
      method: 'GET',
      url: `${process.env.apiUrlV2}/lenders/contacts?consortium=true&${query}`
    }).then(response => {
      commit('SET_CONTACTS', response.data);
      return response.data;
    })
      .catch(e => { console.log(e) });

    return contacts;
  },

  async requestAccess({ dispatch }: any, { email = "", _id = "" }: any) {
    const accessToken = Axios({
      method: 'POST',
      url: `${process.env.apiUrlV2}/projects/access/${_id}`,
      data: { email: email }
    }).then(response => {
      console.log(response.data);
      return response.data;
    })
      .catch((e) => { throw e });

    return accessToken;
  },

  async loadPublicProject({ commit }: any, { token, _id }: { token: string, _id: string }) {
    const project = await Axios({
      method: 'GET',
      url: `${process.env.apiUrlV2}/projects/verify/${_id}?access_token=${token}`
    }).then((response) => {
      commit('SET_PROJECT', response.data?.project ?? {});
      commit('SET_EMAIL', response.data?.email ?? "");
      return response.data;
    })
      .catch(e => {
        throw e;
      });

      return project;
  },

  async checkProjectAccess(context: any, { token, _id }: { token: string, _id: string }) {
    console.log(token, _id)
    const project = await Axios({
      method: 'GET',
      url: `${process.env.apiUrlV2}/projects/verify/${_id}?access_token=${token}`
    }).then((response) => {
      return true;
    })
      .catch(e => {
        return false;
      });

      return project;
  },

  async loadPreview({ commit }: any, _id: string) {
    const project = await Axios({
      method: 'GET',
      url: `${process.env.apiUrlV2}/projects/${_id}/preview`
    }).then(response => {
      commit('SET_PREVIEW', response.data);
      return response.data;
    })
      .catch(e => { throw e });

    return project;
  },

  async addInvitationToOutcomes(context: any, {invitation, projectId}) {
    const outcome = await Axios({
      method: 'POST',
      url: `${process.env.apiUrlV2}/project-outcome/create/${projectId}`,
      data: {
        invitationEmail: invitation,
        status: 'invited'
      }
    }).then(res => res);

    return outcome;
  }
}