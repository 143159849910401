import Axios from "axios"
const cookie = require('cookie');

export default ({ req, redirect, store }: any) => {
  if (!!process.server) return;

  Axios.interceptors.request.use((config) => {
    // console.log(process.browser, process.client, process.server);

    try {
      if (!!process.browser || !!process.client) {
        const token = getAuthTokenFromCookie(document?.cookie || "");
        if (!!token) Object.assign(config.headers, { 'Authorization': `Bearer ${token}` });
      } else {
        // console.log('CONFIG: ', config)
        // console.log('REQ: ', req)
        // console.log(config)
        // const token = getAuthTokenFromCookie(req?.headers?.cookie || "");
        // console.log(token);
        // if (!!token) Object.assign(config.headers, { 'Authorization': `Bearer ${token}` });
      }
    } catch (e) {
      console.log(e);
    }

    return config;
  })
  Axios.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    if (error?.response?.data?.status == 401 || error?.response?.status == 401 || error?.response?.data?.status == 403 || error?.response?.status == 403) {
      store.dispatch('signOut');
      store.commit('DELETE_USER');
      delete Axios.defaults.headers["Authorization"];
      return redirect('/login');
    }

    if (error?.response?.data?.status == 400 || error?.response?.status == 400) {
      throw error.response;
    }
  });
  Axios.defaults.withCredentials = true;
  if ((process.browser || process.client) && !!document) {
    const token = getAuthTokenFromCookie(document?.cookie || "");
    if (!!token) {
      // Axios.defaults.headers['Authorization'] = `Bearer ${token}`;
    }
  } else if (process.server || !document || req?.headers?.cookie) {
    const token = getAuthTokenFromCookie(req?.headers?.cookie || "");
    if (!!token) {
      // Axios.defaults.headers['Authorization'] = `Bearer ${token}`;
    }
  }
}

function getAuthTokenFromCookie(cookieOrigin: string): string {
  const parsed = cookie.parse(cookieOrigin ?? '');
  const token = !!parsed ? parsed['finlistAuthToken'] : "";

  return token;
}
