import { Context } from "@nuxt/types";
import Axios from "axios";

export const state = () => ({
  city: '',
  location: '',
  suggestions: [],
  offerCount: 0,
  assetCount: 0
});

export const mutations = {
  SET_LOCATION_AND_CITY(state: any, location: any) {
    if (!!location) {
      state.city = location.city;
      state.location = location.location;
      state.suggestions = location.suggestions;
      state.offerCount = location.offerCount;
    }
  },

  SET_ASSET_COUNT(state: any, count) {
    state.assetCount = count;
  }
}

export const actions = {
  async fetchLocation(context: any, locationSlug: string) {
    const locationQuery = encodeURIComponent(locationSlug);

    const locationData = await Axios({
      method: 'GET',
      url: `${process.env.apiUrl}/locations/${locationQuery}`
    }).then(response => response.data)
      .catch(error => {
        throw error;
      });

    await context.commit('SET_LOCATION_AND_CITY', locationData)
  },

  async fetchAsset(context: any, usageType: string) {
    const count = await Axios({
      method: 'GET',
      url: `${process.env.apiUrl}/assets/${usageType}`
    }).then(response => response.data)
      .catch(err => { throw err; });

      await context.commit('SET_ASSET_COUNT', count);
  }
}
