import Axios from 'axios';

export interface RegistrationInput {
  type: string;
  fullName: string;
  email: string;
  acceptedTerms: boolean;
}

export const state = () => ({
  success: false
});

export const mutations = {
  SET_SUCCESS(state: any, payload: boolean) {
    state.success = payload;
  }
}

export const actions = {
  async register(context: any, payload: RegistrationInput) {
    const registrationState = await Axios({
      method: 'POST',
      url: `${process.env.apiUrl}/registrations`,
      data: payload
    }).then(response => response.data)
      .catch(error => { throw error })
  }
}
