
import { Component, Vue } from "nuxt-property-decorator";
import CommandCenter from "./command-center.vue";
import SubscriptionStatus from "./subscription-status.vue";

interface IPage {
  title: string;
  link: string;
  icon?: string;
  legacy?: boolean;
  children?: IPage[];
}

@Component({
  name: "CustomerNavbar",
  components: {
    subscriptionStatus: SubscriptionStatus,
    commandCenter: CommandCenter,
  },
})
export default class CustomerNavbar extends Vue {
  public mobileMenuOpen: boolean = false;
  public commandCenterOpen: boolean = false;

  public get initials(): string {
    const { user } = this.$store.state;
    if (!user) return "FL";
    return user?.fullName?.charAt(0) ?? "FL";
  }

  public get packageIsValid(): boolean {
    const { user } = this.$store.state;
    if (!user) return false;
    if (!user?.packageEndDate) return false;
    return new Date(user?.packageEndDate)?.getTime() >= Date.now() && this.$store.state.user?.boughtPackage;
  }

  public get enableLegacyFeatures(): boolean {
    return !!this.$store.state.user?.enableLegacyFeatures;
  }

  public get visiblePages(): IPage[] {
    return this.pages?.filter((page: IPage) => {
      return !page.legacy || !!this.enableLegacyFeatures;
    });
  }

  public get currentOpenPage(): string {
    const { path } = this.$route;
    const parent: string = path?.split("/")?.[2];
    return parent;
  }

  public get currentPageHasChildren(): boolean {
    return !!this.visiblePages?.find((page: IPage) => page.link === this.currentOpenPage)?.children?.length;
  }

  public get constructedParentLink(): string {
    return `/customer/${this.currentOpenPage}`;
  }

  public get visibleChildren(): IPage[] {
    return (
      this.visiblePages?.find((page: IPage) => {
        return page.link === this.currentOpenPage;
      })?.children ?? []
    );
  }

  public openSupport(): void {
    this.$emit("supportOpened", true);
  }

  public handleOutsideClick(event: any): void {
    if ([...event?.target?.classList]?.includes("v-overlay__scrim")) this.commandCenterOpen = false;
  }

  public pages: IPage[] = [
    // {
    // 	title: "Dashboard",
    // 	link: "",
    // 	icon: "mdi-home",
    // },
    {
      title: "Aktivitäten",
      link: "feed",
      icon: "mdi-bell-outline",
      legacy: true,
    },
    {
      title: "Kreditgeber-Suche",
      link: "lender-search",
      icon: "mdi-magnify",
      legacy: false,
    },
    {
      title: "Kreditgeber-Listen",
      link: "lists",
      icon: "mdi-format-list-text",
      legacy: true,
      children: [
        {
          title: "Kontakte",
          link: "lists/contacts",
          icon: "mdi-account",
        },
        {
          title: "Archiv",
          link: "lists/archive",
          icon: "mdi-archive-outline",
        },
      ],
    },
    {
      title: "Projekte",
      link: "projects",
      icon: "mdi-account-hard-hat",
      legacy: false,
      children: [
        {
          title: "Versendete Anfragen",
          link: "projects/sent-projects",
          icon: "mdi-email-fast",
          legacy: false,
        },
        {
          title: "Archiv",
          link: "projects/archive",
          icon: "mdi-archive-outline",
        },
        // {
        // 	title: "Dealflow",
        // 	link: "/customer/dealflow",
        // 	icon: "mdi-view-dashboard",
        // },
        // {
        // 	title: "Track-Record",
        // 	link: "/customer/trackrecord",
        // 	icon: "mdi-trophy-variant-outline",
        // }
      ],
    },
    {
      title: "Suchaufträge",
      link: "search-alerts",
      icon: "mdi-message-badge",
      legacy: false,
    },
    {
      title: "Einstellungen",
      link: "settings",
      icon: "mdi-cog",
      legacy: false,
      children: [
        {
          title: "Rechnungen",
          link: "settings/invoices",
          icon: "mdi-receipt",
          legacy: false,
        },
      ],
    },
  ];

  public signOut(): void {
    this.$store
      .dispatch("signOut")
      .then((response) => {
        if (typeof window !== "undefined") {
          window.location.pathname = "/";
        }
      })
      .catch((error) => {
        //@ts-ignore
        this.$notify({
          type: "error",
          message: "Logout fehlgeschlagen",
        });
        if (typeof window !== "undefined") {
          window.location.pathname = "/login";
        }
      });
  }
}
