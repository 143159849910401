import Axios from "axios";
import { Profile } from "../model/profile";
export interface ProfileState {
  profiles: Profile[];
  currentProfile?: Partial<Profile>;
}

export const state = (): ProfileState => ({
  profiles: [],
  currentProfile: {}
});

export const mutations = {
  SET_PROFILES(state: ProfileState, profiles: Profile[]) {
    state.profiles = profiles;
  }
}

export const actions = {
  async fetchProfiles({ commit }: any) {
    const profiles = await Axios({
      method: 'GET',
      url: `${process.env.apiUrlV2}/profiles`
    }).then(response => {
      const { data } = response;
      commit('SET_PROFILES', data);
      return data;
    })
      .catch(e => { throw e });

    return profiles;
  },

  async createProfile({ dispatch }: any, body: Partial<Profile>) {
    const profile = await Axios({
      method: 'POST',
      url: `${process.env.apiUrlV2}/profiles`,
      data: body
    }).then(response => {
      const { data } = response;
      dispatch('fetchProfiles');
      return data;
    })
      .catch(e => { throw e });

    return profile;
  },

  async editProfile({ dispatch }: any, body: Partial<Profile>) {
    const profile = await Axios({
      method: 'PATCH',
      url: `${process.env.apiUrlV2}/profiles/edit/${body.id}`,
      data: body
    }).then(response => {
      const { data } = response;
      dispatch('fetchProfiles');
      return data;
    })
      .catch(e => { throw e });

    return profile;
  },

  async linkProfileToProject({ dispatch }: any, { projectId, profileId }: { projectId: string, profileId: string }) {
    if (!projectId || !profileId) throw 'Bad data.';
    const profile = await Axios({
      method: 'PATCH',
      url: `${process.env.apiUrlV2}/profiles/link/project/${projectId}/${profileId}`,
    }).then(response => {
      const { data } = response;
      dispatch('fetchProfiles');
      return data;
    })
      .catch(e => { throw e });

    return profile;
  },

  async deleteProfile({ dispatch }: any, id: string) {
    const profile = await Axios({
      method: 'DELETE',
      url: `${process.env.apiUrlV2}/profiles/${id}`,
    }).then(response => {
      const { data } = response;
      return data;
    })
      .catch(e => { throw e })
      .finally(() => {
        setTimeout(() => {
          return dispatch('fetchProfiles');
        }, 5000);
      })

    return profile;
  },
}