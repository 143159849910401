
import { Component, Prop, Vue } from "nuxt-property-decorator";

@Component({})
export default class AccountManager extends Vue {
  @Prop({ required: false, default: "Sandra Olschewski" }) readonly _accountManager!: any;

  public get manager(): any {
    return this.managers.find((m) => m.name === this._accountManager) ?? this.managers.find((m) => m.name === "Sandra Olschewski");
  }

  public copyPhone() {
    try {
      window.navigator.clipboard.writeText(this.manager.phone).then(() => {
        this.$notify({
          type: "success",
          message: "Telefonnummer kopiert.",
        });
      });
    } catch (e) {
      throw e;
    }
  }

  public managers: any[] = [
    
    {
      name: "Sandra Olschewski",
      image: require("~/assets/images/team-images/new/sandra_olschewski.png"),
      phone: "+49 3303 / 5417 240",
      email: "sandra.olschewski@finlist.de",
      // calendly: "https://calendly.com/joschka-kuznik/telefontermin",
      greeting: "Ihre persönliche Ansprechpartnerin",
      initials: "SO",
    }
  ];
}
