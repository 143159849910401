export const state = () => ({
  availableContacts: []
});

export const actions = {
  async getAvailableContacts(context: any) {
    await context.dispatch('projects/fetchProjects', null, {root: true}).then(() => {
      context.commit('SET_AVAILABLE_CONTACTS', context.rootState.projects.projects);
    });
  }
}

export const mutations = {
  SET_AVAILABLE_CONTACTS(state: any, payload: any[]) {
    let availableContacts: any[] = [];
    payload.forEach(p => {
      availableContacts = [...availableContacts, ...p.contacted];
    })
    state.availableContacts = availableContacts.map(c => c._id);
  }
}
