import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _75b92082 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _201e3852 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _22944e46 = () => interopDefault(import('../pages/agb.vue' /* webpackChunkName: "pages/agb" */))
const _104cb16a = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _2c18fe20 = () => interopDefault(import('../pages/cancel.vue' /* webpackChunkName: "pages/cancel" */))
const _8c77c32c = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _48f6a587 = () => interopDefault(import('../pages/customer/index.vue' /* webpackChunkName: "pages/customer/index" */))
const _0d1de5dc = () => interopDefault(import('../pages/customer-registration.vue' /* webpackChunkName: "pages/customer-registration" */))
const _7c252829 = () => interopDefault(import('../pages/demo-confirmation.vue' /* webpackChunkName: "pages/demo-confirmation" */))
const _0fa8be9c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6e1b3918 = () => interopDefault(import('../pages/expo.vue' /* webpackChunkName: "pages/expo" */))
const _59c84534 = () => interopDefault(import('../pages/external/index.vue' /* webpackChunkName: "pages/external/index" */))
const _590f2f01 = () => interopDefault(import('../pages/find-buyers/index.vue' /* webpackChunkName: "pages/find-buyers/index" */))
const _9158f790 = () => interopDefault(import('../pages/for-agents.vue' /* webpackChunkName: "pages/for-agents" */))
const _27c62fd5 = () => interopDefault(import('../pages/for-partners.vue' /* webpackChunkName: "pages/for-partners" */))
const _05a60f12 = () => interopDefault(import('../pages/forgot-password/index.vue' /* webpackChunkName: "pages/forgot-password/index" */))
const _b5395c84 = () => interopDefault(import('../pages/how-to.vue' /* webpackChunkName: "pages/how-to" */))
const _614b19d3 = () => interopDefault(import('../pages/imprint.vue' /* webpackChunkName: "pages/imprint" */))
const _fc84ed08 = () => interopDefault(import('../pages/jobs.vue' /* webpackChunkName: "pages/jobs" */))
const _645f62b8 = () => interopDefault(import('../pages/lenders/index.vue' /* webpackChunkName: "pages/lenders/index" */))
const _4d5f3c9a = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _792bb415 = () => interopDefault(import('../pages/partner/index.vue' /* webpackChunkName: "pages/partner/index" */))
const _0990198d = () => interopDefault(import('../pages/press.vue' /* webpackChunkName: "pages/press" */))
const _14335710 = () => interopDefault(import('../pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _10f029d2 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _612b136e = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _4c9e1f95 = () => interopDefault(import('../pages/registration-success.vue' /* webpackChunkName: "pages/registration-success" */))
const _4614a04c = () => interopDefault(import('../pages/reports/index.vue' /* webpackChunkName: "pages/reports/index" */))
const _c5d47c84 = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _dbb3fc0c = () => interopDefault(import('../pages/temp.vue' /* webpackChunkName: "pages/temp" */))
const _e6e6604c = () => interopDefault(import('../pages/testimonials.vue' /* webpackChunkName: "pages/testimonials" */))
const _3dd076e1 = () => interopDefault(import('../pages/try-finlist.vue' /* webpackChunkName: "pages/try-finlist" */))
const _4ff89154 = () => interopDefault(import('../pages/update-download.vue' /* webpackChunkName: "pages/update-download" */))
const _a0197474 = () => interopDefault(import('../pages/upgrade-account.vue' /* webpackChunkName: "pages/upgrade-account" */))
const _347d1854 = () => interopDefault(import('../pages/x-mas.vue' /* webpackChunkName: "pages/x-mas" */))
const _53737890 = () => interopDefault(import('../pages/customer/dealflow/index.vue' /* webpackChunkName: "pages/customer/dealflow/index" */))
const _42b9be79 = () => interopDefault(import('../pages/customer/feed.vue' /* webpackChunkName: "pages/customer/feed" */))
const _5f1a3c42 = () => interopDefault(import('../pages/customer/legacy-error.vue' /* webpackChunkName: "pages/customer/legacy-error" */))
const _766e56ee = () => interopDefault(import('../pages/customer/lender-search.vue' /* webpackChunkName: "pages/customer/lender-search" */))
const _828ee466 = () => interopDefault(import('../pages/customer/lists/index.vue' /* webpackChunkName: "pages/customer/lists/index" */))
const _566cdc98 = () => interopDefault(import('../pages/customer/projects/index.vue' /* webpackChunkName: "pages/customer/projects/index" */))
const _58292a58 = () => interopDefault(import('../pages/customer/search-alerts/index.vue' /* webpackChunkName: "pages/customer/search-alerts/index" */))
const _4dfe7dfe = () => interopDefault(import('../pages/customer/settings/index.vue' /* webpackChunkName: "pages/customer/settings/index" */))
const _7e8c4647 = () => interopDefault(import('../pages/customer/support/index.vue' /* webpackChunkName: "pages/customer/support/index" */))
const _f9f8c5d8 = () => interopDefault(import('../pages/customer/trackrecord/index.vue' /* webpackChunkName: "pages/customer/trackrecord/index" */))
const _1020016c = () => interopDefault(import('../pages/external/market.vue' /* webpackChunkName: "pages/external/market" */))
const _6cf8d7c9 = () => interopDefault(import('../pages/forgot-password/reset-password.vue' /* webpackChunkName: "pages/forgot-password/reset-password" */))
const _33381198 = () => interopDefault(import('../pages/purchase/upgrade.vue' /* webpackChunkName: "pages/purchase/upgrade" */))
const _7024c397 = () => interopDefault(import('../pages/connect/google/redirect.vue' /* webpackChunkName: "pages/connect/google/redirect" */))
const _3b5f2c9c = () => interopDefault(import('../pages/connect/linkedin/redirect.vue' /* webpackChunkName: "pages/connect/linkedin/redirect" */))
const _207b80fd = () => interopDefault(import('../pages/customer/lists/archive.vue' /* webpackChunkName: "pages/customer/lists/archive" */))
const _b9a931b0 = () => interopDefault(import('../pages/customer/lists/contacts.vue' /* webpackChunkName: "pages/customer/lists/contacts" */))
const _7a95bb9e = () => interopDefault(import('../pages/customer/lists/results.vue' /* webpackChunkName: "pages/customer/lists/results" */))
const _c7516df0 = () => interopDefault(import('../pages/customer/projects/archive.vue' /* webpackChunkName: "pages/customer/projects/archive" */))
const _5b666dd0 = () => interopDefault(import('../pages/customer/projects/sent-projects/index.vue' /* webpackChunkName: "pages/customer/projects/sent-projects/index" */))
const _177e9d0f = () => interopDefault(import('../pages/customer/projects/submit-consent.vue' /* webpackChunkName: "pages/customer/projects/submit-consent" */))
const _8d7e046c = () => interopDefault(import('../pages/customer/settings/invoices/index.vue' /* webpackChunkName: "pages/customer/settings/invoices/index" */))
const _2d7cbe90 = () => interopDefault(import('../pages/customer/support/faqs.vue' /* webpackChunkName: "pages/customer/support/faqs" */))
const _0829816b = () => interopDefault(import('../pages/customer/projects/document-download/_id.vue' /* webpackChunkName: "pages/customer/projects/document-download/_id" */))
const _204acadd = () => interopDefault(import('../pages/customer/projects/download/_id.vue' /* webpackChunkName: "pages/customer/projects/download/_id" */))
const _55bca75f = () => interopDefault(import('../pages/customer/projects/edit/_id.vue' /* webpackChunkName: "pages/customer/projects/edit/_id" */))
const _592b0644 = () => interopDefault(import('../pages/customer/projects/invite/_id.vue' /* webpackChunkName: "pages/customer/projects/invite/_id" */))
const _a67349f0 = () => interopDefault(import('../pages/customer/projects/lenders/_id.vue' /* webpackChunkName: "pages/customer/projects/lenders/_id" */))
const _50feaa46 = () => interopDefault(import('../pages/customer/projects/send/_id.vue' /* webpackChunkName: "pages/customer/projects/send/_id" */))
const _1ca4723e = () => interopDefault(import('../pages/customer/trackrecord/download/_id.vue' /* webpackChunkName: "pages/customer/trackrecord/download/_id" */))
const _30e64563 = () => interopDefault(import('../pages/customer/trackrecord/edit/_id.vue' /* webpackChunkName: "pages/customer/trackrecord/edit/_id" */))
const _9aab6e3e = () => interopDefault(import('../pages/customer/trackrecord/send/_id.vue' /* webpackChunkName: "pages/customer/trackrecord/send/_id" */))
const _56b99f75 = () => interopDefault(import('../pages/customer/lists/_id.vue' /* webpackChunkName: "pages/customer/lists/_id" */))
const _50392500 = () => interopDefault(import('../pages/customer/projects/_id.vue' /* webpackChunkName: "pages/customer/projects/_id" */))
const _2b3dd47c = () => interopDefault(import('../pages/customer/trackrecord/_id.vue' /* webpackChunkName: "pages/customer/trackrecord/_id" */))
const _280ba488 = () => interopDefault(import('../pages/organization-data-update/edit/_id.vue' /* webpackChunkName: "pages/organization-data-update/edit/_id" */))
const _8bc0eee4 = () => interopDefault(import('../pages/projects/access/_id.vue' /* webpackChunkName: "pages/projects/access/_id" */))
const _30ffe894 = () => interopDefault(import('../pages/projects/invitations/_id.vue' /* webpackChunkName: "pages/projects/invitations/_id" */))
const _6ff36a04 = () => interopDefault(import('../pages/projects/preview/_id.vue' /* webpackChunkName: "pages/projects/preview/_id" */))
const _13b9f3fa = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _54634efa = () => interopDefault(import('../pages/organization-data-update/_id.vue' /* webpackChunkName: "pages/organization-data-update/_id" */))
const _bc0375e4 = () => interopDefault(import('../pages/project/_id.vue' /* webpackChunkName: "pages/project/_id" */))
const _26a645b4 = () => interopDefault(import('../pages/reports/_id.vue' /* webpackChunkName: "pages/reports/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _75b92082,
    name: "404___de"
  }, {
    path: "/about",
    component: _201e3852,
    name: "about___de"
  }, {
    path: "/agb",
    component: _22944e46,
    name: "agb___de"
  }, {
    path: "/blog",
    component: _104cb16a,
    name: "blog___de"
  }, {
    path: "/cancel",
    component: _2c18fe20,
    name: "cancel___de"
  }, {
    path: "/contact",
    component: _8c77c32c,
    name: "contact___de"
  }, {
    path: "/customer",
    component: _48f6a587,
    name: "customer___de"
  }, {
    path: "/customer-registration",
    component: _0d1de5dc,
    name: "customer-registration___de"
  }, {
    path: "/demo-confirmation",
    component: _7c252829,
    name: "demo-confirmation___de"
  }, {
    path: "/en",
    component: _0fa8be9c,
    name: "index___en"
  }, {
    path: "/expo",
    component: _6e1b3918,
    name: "expo___de"
  }, {
    path: "/external",
    component: _59c84534,
    name: "external___de"
  }, {
    path: "/find-buyers",
    component: _590f2f01,
    name: "find-buyers___de"
  }, {
    path: "/for-agents",
    component: _9158f790,
    name: "for-agents___de"
  }, {
    path: "/for-partners",
    component: _27c62fd5,
    name: "for-partners___de"
  }, {
    path: "/forgot-password",
    component: _05a60f12,
    name: "forgot-password___de"
  }, {
    path: "/how-to",
    component: _b5395c84,
    name: "how-to___de"
  }, {
    path: "/imprint",
    component: _614b19d3,
    name: "imprint___de"
  }, {
    path: "/jobs",
    component: _fc84ed08,
    name: "jobs___de"
  }, {
    path: "/lenders",
    component: _645f62b8,
    name: "lenders___de"
  }, {
    path: "/login",
    component: _4d5f3c9a,
    name: "login___de"
  }, {
    path: "/partner",
    component: _792bb415,
    name: "partner___de"
  }, {
    path: "/press",
    component: _0990198d,
    name: "press___de"
  }, {
    path: "/pricing",
    component: _14335710,
    name: "pricing___de"
  }, {
    path: "/privacy",
    component: _10f029d2,
    name: "privacy___de"
  }, {
    path: "/register",
    component: _612b136e,
    name: "register___de"
  }, {
    path: "/registration-success",
    component: _4c9e1f95,
    name: "registration-success___de"
  }, {
    path: "/reports",
    component: _4614a04c,
    name: "reports___de"
  }, {
    path: "/signup",
    component: _c5d47c84,
    name: "signup___de"
  }, {
    path: "/temp",
    component: _dbb3fc0c,
    name: "temp___de"
  }, {
    path: "/testimonials",
    component: _e6e6604c,
    name: "testimonials___de"
  }, {
    path: "/try-finlist",
    component: _3dd076e1,
    name: "try-finlist___de"
  }, {
    path: "/update-download",
    component: _4ff89154,
    name: "update-download___de"
  }, {
    path: "/upgrade-account",
    component: _a0197474,
    name: "upgrade-account___de"
  }, {
    path: "/x-mas",
    component: _347d1854,
    name: "x-mas___de"
  }, {
    path: "/customer/dealflow",
    component: _53737890,
    name: "customer-dealflow___de"
  }, {
    path: "/customer/feed",
    component: _42b9be79,
    name: "customer-feed___de"
  }, {
    path: "/customer/legacy-error",
    component: _5f1a3c42,
    name: "customer-legacy-error___de"
  }, {
    path: "/customer/lender-search",
    component: _766e56ee,
    name: "customer-lender-search___de"
  }, {
    path: "/customer/lists",
    component: _828ee466,
    name: "customer-lists___de"
  }, {
    path: "/customer/projects",
    component: _566cdc98,
    name: "customer-projects___de"
  }, {
    path: "/customer/search-alerts",
    component: _58292a58,
    name: "customer-search-alerts___de"
  }, {
    path: "/customer/settings",
    component: _4dfe7dfe,
    name: "customer-settings___de"
  }, {
    path: "/customer/support",
    component: _7e8c4647,
    name: "customer-support___de"
  }, {
    path: "/customer/trackrecord",
    component: _f9f8c5d8,
    name: "customer-trackrecord___de"
  }, {
    path: "/en/404",
    component: _75b92082,
    name: "404___en"
  }, {
    path: "/en/about",
    component: _201e3852,
    name: "about___en"
  }, {
    path: "/en/agb",
    component: _22944e46,
    name: "agb___en"
  }, {
    path: "/en/blog",
    component: _104cb16a,
    name: "blog___en"
  }, {
    path: "/en/cancel",
    component: _2c18fe20,
    name: "cancel___en"
  }, {
    path: "/en/contact",
    component: _8c77c32c,
    name: "contact___en"
  }, {
    path: "/en/customer",
    component: _48f6a587,
    name: "customer___en"
  }, {
    path: "/en/customer-registration",
    component: _0d1de5dc,
    name: "customer-registration___en"
  }, {
    path: "/en/demo-confirmation",
    component: _7c252829,
    name: "demo-confirmation___en"
  }, {
    path: "/en/expo",
    component: _6e1b3918,
    name: "expo___en"
  }, {
    path: "/en/external",
    component: _59c84534,
    name: "external___en"
  }, {
    path: "/en/find-buyers",
    component: _590f2f01,
    name: "find-buyers___en"
  }, {
    path: "/en/for-agents",
    component: _9158f790,
    name: "for-agents___en"
  }, {
    path: "/en/for-partners",
    component: _27c62fd5,
    name: "for-partners___en"
  }, {
    path: "/en/forgot-password",
    component: _05a60f12,
    name: "forgot-password___en"
  }, {
    path: "/en/how-to",
    component: _b5395c84,
    name: "how-to___en"
  }, {
    path: "/en/imprint",
    component: _614b19d3,
    name: "imprint___en"
  }, {
    path: "/en/jobs",
    component: _fc84ed08,
    name: "jobs___en"
  }, {
    path: "/en/lenders",
    component: _645f62b8,
    name: "lenders___en"
  }, {
    path: "/en/login",
    component: _4d5f3c9a,
    name: "login___en"
  }, {
    path: "/en/partner",
    component: _792bb415,
    name: "partner___en"
  }, {
    path: "/en/press",
    component: _0990198d,
    name: "press___en"
  }, {
    path: "/en/pricing",
    component: _14335710,
    name: "pricing___en"
  }, {
    path: "/en/privacy",
    component: _10f029d2,
    name: "privacy___en"
  }, {
    path: "/en/register",
    component: _612b136e,
    name: "register___en"
  }, {
    path: "/en/registration-success",
    component: _4c9e1f95,
    name: "registration-success___en"
  }, {
    path: "/en/reports",
    component: _4614a04c,
    name: "reports___en"
  }, {
    path: "/en/signup",
    component: _c5d47c84,
    name: "signup___en"
  }, {
    path: "/en/temp",
    component: _dbb3fc0c,
    name: "temp___en"
  }, {
    path: "/en/testimonials",
    component: _e6e6604c,
    name: "testimonials___en"
  }, {
    path: "/en/try-finlist",
    component: _3dd076e1,
    name: "try-finlist___en"
  }, {
    path: "/en/update-download",
    component: _4ff89154,
    name: "update-download___en"
  }, {
    path: "/en/upgrade-account",
    component: _a0197474,
    name: "upgrade-account___en"
  }, {
    path: "/en/x-mas",
    component: _347d1854,
    name: "x-mas___en"
  }, {
    path: "/external/market",
    component: _1020016c,
    name: "external-market___de"
  }, {
    path: "/forgot-password/reset-password",
    component: _6cf8d7c9,
    name: "forgot-password-reset-password___de"
  }, {
    path: "/purchase/upgrade",
    component: _33381198,
    name: "purchase-upgrade___de"
  }, {
    path: "/connect/google/redirect",
    component: _7024c397,
    name: "connect-google-redirect___de"
  }, {
    path: "/connect/linkedin/redirect",
    component: _3b5f2c9c,
    name: "connect-linkedin-redirect___de"
  }, {
    path: "/customer/lists/archive",
    component: _207b80fd,
    name: "customer-lists-archive___de"
  }, {
    path: "/customer/lists/contacts",
    component: _b9a931b0,
    name: "customer-lists-contacts___de"
  }, {
    path: "/customer/lists/results",
    component: _7a95bb9e,
    name: "customer-lists-results___de"
  }, {
    path: "/customer/projects/archive",
    component: _c7516df0,
    name: "customer-projects-archive___de"
  }, {
    path: "/customer/projects/sent-projects",
    component: _5b666dd0,
    name: "customer-projects-sent-projects___de"
  }, {
    path: "/customer/projects/submit-consent",
    component: _177e9d0f,
    name: "customer-projects-submit-consent___de"
  }, {
    path: "/customer/settings/invoices",
    component: _8d7e046c,
    name: "customer-settings-invoices___de"
  }, {
    path: "/customer/support/faqs",
    component: _2d7cbe90,
    name: "customer-support-faqs___de"
  }, {
    path: "/en/customer/dealflow",
    component: _53737890,
    name: "customer-dealflow___en"
  }, {
    path: "/en/customer/feed",
    component: _42b9be79,
    name: "customer-feed___en"
  }, {
    path: "/en/customer/legacy-error",
    component: _5f1a3c42,
    name: "customer-legacy-error___en"
  }, {
    path: "/en/customer/lender-search",
    component: _766e56ee,
    name: "customer-lender-search___en"
  }, {
    path: "/en/customer/lists",
    component: _828ee466,
    name: "customer-lists___en"
  }, {
    path: "/en/customer/projects",
    component: _566cdc98,
    name: "customer-projects___en"
  }, {
    path: "/en/customer/search-alerts",
    component: _58292a58,
    name: "customer-search-alerts___en"
  }, {
    path: "/en/customer/settings",
    component: _4dfe7dfe,
    name: "customer-settings___en"
  }, {
    path: "/en/customer/support",
    component: _7e8c4647,
    name: "customer-support___en"
  }, {
    path: "/en/customer/trackrecord",
    component: _f9f8c5d8,
    name: "customer-trackrecord___en"
  }, {
    path: "/en/external/market",
    component: _1020016c,
    name: "external-market___en"
  }, {
    path: "/en/forgot-password/reset-password",
    component: _6cf8d7c9,
    name: "forgot-password-reset-password___en"
  }, {
    path: "/en/purchase/upgrade",
    component: _33381198,
    name: "purchase-upgrade___en"
  }, {
    path: "/en/connect/google/redirect",
    component: _7024c397,
    name: "connect-google-redirect___en"
  }, {
    path: "/en/connect/linkedin/redirect",
    component: _3b5f2c9c,
    name: "connect-linkedin-redirect___en"
  }, {
    path: "/en/customer/lists/archive",
    component: _207b80fd,
    name: "customer-lists-archive___en"
  }, {
    path: "/en/customer/lists/contacts",
    component: _b9a931b0,
    name: "customer-lists-contacts___en"
  }, {
    path: "/en/customer/lists/results",
    component: _7a95bb9e,
    name: "customer-lists-results___en"
  }, {
    path: "/en/customer/projects/archive",
    component: _c7516df0,
    name: "customer-projects-archive___en"
  }, {
    path: "/en/customer/projects/sent-projects",
    component: _5b666dd0,
    name: "customer-projects-sent-projects___en"
  }, {
    path: "/en/customer/projects/submit-consent",
    component: _177e9d0f,
    name: "customer-projects-submit-consent___en"
  }, {
    path: "/en/customer/settings/invoices",
    component: _8d7e046c,
    name: "customer-settings-invoices___en"
  }, {
    path: "/en/customer/support/faqs",
    component: _2d7cbe90,
    name: "customer-support-faqs___en"
  }, {
    path: "/en/customer/projects/document-download/:id?",
    component: _0829816b,
    name: "customer-projects-document-download-id___en"
  }, {
    path: "/en/customer/projects/download/:id?",
    component: _204acadd,
    name: "customer-projects-download-id___en"
  }, {
    path: "/en/customer/projects/edit/:id?",
    component: _55bca75f,
    name: "customer-projects-edit-id___en"
  }, {
    path: "/en/customer/projects/invite/:id?",
    component: _592b0644,
    name: "customer-projects-invite-id___en"
  }, {
    path: "/en/customer/projects/lenders/:id?",
    component: _a67349f0,
    name: "customer-projects-lenders-id___en"
  }, {
    path: "/en/customer/projects/send/:id?",
    component: _50feaa46,
    name: "customer-projects-send-id___en"
  }, {
    path: "/en/customer/trackrecord/download/:id?",
    component: _1ca4723e,
    name: "customer-trackrecord-download-id___en"
  }, {
    path: "/en/customer/trackrecord/edit/:id?",
    component: _30e64563,
    name: "customer-trackrecord-edit-id___en"
  }, {
    path: "/en/customer/trackrecord/send/:id?",
    component: _9aab6e3e,
    name: "customer-trackrecord-send-id___en"
  }, {
    path: "/customer/projects/document-download/:id?",
    component: _0829816b,
    name: "customer-projects-document-download-id___de"
  }, {
    path: "/customer/projects/download/:id?",
    component: _204acadd,
    name: "customer-projects-download-id___de"
  }, {
    path: "/customer/projects/edit/:id?",
    component: _55bca75f,
    name: "customer-projects-edit-id___de"
  }, {
    path: "/customer/projects/invite/:id?",
    component: _592b0644,
    name: "customer-projects-invite-id___de"
  }, {
    path: "/customer/projects/lenders/:id?",
    component: _a67349f0,
    name: "customer-projects-lenders-id___de"
  }, {
    path: "/customer/projects/send/:id?",
    component: _50feaa46,
    name: "customer-projects-send-id___de"
  }, {
    path: "/customer/trackrecord/download/:id?",
    component: _1ca4723e,
    name: "customer-trackrecord-download-id___de"
  }, {
    path: "/customer/trackrecord/edit/:id?",
    component: _30e64563,
    name: "customer-trackrecord-edit-id___de"
  }, {
    path: "/customer/trackrecord/send/:id?",
    component: _9aab6e3e,
    name: "customer-trackrecord-send-id___de"
  }, {
    path: "/en/customer/lists/:id",
    component: _56b99f75,
    name: "customer-lists-id___en"
  }, {
    path: "/en/customer/projects/:id",
    component: _50392500,
    name: "customer-projects-id___en"
  }, {
    path: "/en/customer/trackrecord/:id",
    component: _2b3dd47c,
    name: "customer-trackrecord-id___en"
  }, {
    path: "/en/organization-data-update/edit/:id?",
    component: _280ba488,
    name: "organization-data-update-edit-id___en"
  }, {
    path: "/en/projects/access/:id?",
    component: _8bc0eee4,
    name: "projects-access-id___en"
  }, {
    path: "/en/projects/invitations/:id?",
    component: _30ffe894,
    name: "projects-invitations-id___en"
  }, {
    path: "/en/projects/preview/:id?",
    component: _6ff36a04,
    name: "projects-preview-id___en"
  }, {
    path: "/customer/lists/:id",
    component: _56b99f75,
    name: "customer-lists-id___de"
  }, {
    path: "/customer/projects/:id",
    component: _50392500,
    name: "customer-projects-id___de"
  }, {
    path: "/customer/trackrecord/:id",
    component: _2b3dd47c,
    name: "customer-trackrecord-id___de"
  }, {
    path: "/en/blog/:slug",
    component: _13b9f3fa,
    name: "blog-slug___en"
  }, {
    path: "/en/organization-data-update/:id?",
    component: _54634efa,
    name: "organization-data-update-id___en"
  }, {
    path: "/en/project/:id?",
    component: _bc0375e4,
    name: "project-id___en"
  }, {
    path: "/en/reports/:id",
    component: _26a645b4,
    name: "reports-id___en"
  }, {
    path: "/organization-data-update/edit/:id?",
    component: _280ba488,
    name: "organization-data-update-edit-id___de"
  }, {
    path: "/projects/access/:id?",
    component: _8bc0eee4,
    name: "projects-access-id___de"
  }, {
    path: "/projects/invitations/:id?",
    component: _30ffe894,
    name: "projects-invitations-id___de"
  }, {
    path: "/projects/preview/:id?",
    component: _6ff36a04,
    name: "projects-preview-id___de"
  }, {
    path: "/blog/:slug",
    component: _13b9f3fa,
    name: "blog-slug___de"
  }, {
    path: "/organization-data-update/:id?",
    component: _54634efa,
    name: "organization-data-update-id___de"
  }, {
    path: "/project/:id?",
    component: _bc0375e4,
    name: "project-id___de"
  }, {
    path: "/reports/:id",
    component: _26a645b4,
    name: "reports-id___de"
  }, {
    path: "/",
    component: _0fa8be9c,
    name: "index___de"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
