
import { Component, Vue } from "nuxt-property-decorator";
import { IProject } from "../../model/project";

interface ICommandCenterItem {
  title: string;
  breadcrumbs?: string[];
  link: string;
  type: string;
  description?: string;
  projectContent?: any;
  blank: string;
}

@Component
export default class CommandCenter extends Vue {
  public searchTerm: string = "";
  public get projects() {
    return this.$store.state.projects?.projects;
  }

  public closeCommandCenter(): void {
    this.$emit("closed", true);
  }

  private async setUpProjects() {
    const user = await this.$store.dispatch("verifyUser");
    const projects = await this.$store.dispatch("projects/fetchProjects");

    Promise.all([user, projects])
      .then(() => {})
      .catch((e) => {
        this.$notify({
          message: "Etwas ist schief gelaufen. Bitte versuche es nochmal.",
          type: "error",
        });
      });
  }

  public get visiblePages(): ICommandCenterItem[] {
    return [
      { title: "Dashboard", link: "/customer", type: "page", description: "Ihre Startseite", breadcrumbs: ["Seite"], blank:"" },
      { title: "Projekte", link: "/customer/projects", type: "page", description: "Übersicht über Ihre letzten Projekte", breadcrumbs: ["Seite"], blank:"" },
      { title: "Projekt Archiv", link: "/customer/projects/archive", type: "page", description: "Ihre archivierten Projekte", breadcrumbs: ["Projekte", ">", "Archiv"], blank:"" },
      { title: "Versendete Projekte", link: "/customer/projects/sent-projects", type: "page", description: "Ein Überblick über Ihre bisher versendeten Projekte", breadcrumbs: ["Projekte", ">", "Versendete Projekte"], blank:"" },
      { title: "Suchaufträge", link: "/customer/search-alerts", type: "page", description: "Erstellen Sie einen Suchauftrag, um neue Objekte zu finden", breadcrumbs: ["Seite"], blank:"" },
      { title: "Kreditgeber-Suche", link: "/customer/lender-search", type: "page", description: "Verschaffen Sie sich einen Überblick über das FinList Kreditgebernetzwerk", breadcrumbs: ["Seite"], blank:"" },
      { title: "Einstellungen", link: "/customer/settings", type: "page", description: "Bearbeiten Sie Ihre Kontaktdaten und Benachrichtigungseinstellungen", breadcrumbs: ["Seite"], blank:"" },
      { title: "Rechnungen", link: "/customer/settings/invoices", type: "page", description: "Ihre Rechnungen im Überblick", breadcrumbs: ["Einstellungen", ">", "Rechnungen"], blank:"" },
    ];
  }

  public get items(): ICommandCenterItem[] {
    const projects: IProject[] = this.$store.state.projects?.projects ?? [];

    return [
      ...this.visiblePages,
      ...projects?.map((p) => {
        return {
          title: p.title as string,
          breadcrumbs: ["Projekt"],
          link: `/customer/projects/${p.id}`,
          type: "project",
          blank: "",
          projectContent: {
            location: this.$options.filters?.parseLocation(p.location),
            completion: (p?.completion?.total * 100)?.toFixed(0) ?? 0,
            createdAt: this.$options.filters?.parseDateTime(p.createdAt),
            requiredCapital: p.requiredCapital ?? "-",
            typeOf: p.typeOf ?? "-",
            totalRequiredCapital: this.$options.filters?.parsePrice(p.totalRequiredCapital),
          },
        };
      }),
    ];
  }

  public getColor(input: number): string {
    if (input < 30) return "error";
    if (input >= 30 && input < 70) return "warning";
    if (input >= 70) return "success";
    return "primary";
  }

  public commandFilter(item: any, queryText: string, itemText: string): boolean {
    return [
      item.title?.toLowerCase().includes(queryText?.toLowerCase()),
      item.description?.toLowerCase().includes(queryText?.toLowerCase()),
      item.link?.toLowerCase().includes(queryText?.toLowerCase()),
      item.projectContent?.location?.toLowerCase().includes(queryText?.toLowerCase()),
    ].some((condition: boolean) => !!condition);
  }

  public selectCommandItem(link: string) {
    this.searchTerm = "";
    this.$router.push(link);
    this.closeCommandCenter();
  }

  public handleIntersection(entries, observer) {
    setTimeout(() => {
      this.searchTerm = "";
      // @ts-ignore
      document.getElementById("command-center-input").click();
    }, 500)

    console.log(this.searchTerm)
  }

  mounted() {
    if (typeof window !== "undefined") {
      window.onkeyup = null;
      function handleEscapeKey(event) {
        if (!event?.keyCode && !event?.key && !event.code) return;
        if (event?.keyCode == 27 || event?.key?.toLowerCase() == "escape" || event?.code?.toLowerCase() == "escape") {
          // @ts-ignore
          this.closeCommandCenter();
        }

        return;
      }

      window.onkeyup = handleEscapeKey.bind(this);
    }
    if (!this.projects?.length) return this.setUpProjects();
  }
}
