import Axios from "axios";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, UserCredential } from "firebase/auth"
import { auth } from "./init"

export const signInWithCredentials = async ({ email, password }: { email: string, password: string }) => {
  try {
    return signInWithEmailAndPassword(auth, email, password).then((credentials: UserCredential) => {
      return credentials;
    })
  } catch (e) {
    throw e;
  }
}

export const signUp = async ({ email, password }: { email: string, password: string }) => {
  try {
    return createUserWithEmailAndPassword(auth, email, password)
      .then((credentials: UserCredential) => {
        return credentials;
      })
      .catch(e => signInWithCredentials({ email, password }))
  } catch (e) {
    return signInWithCredentials({ email, password });
  }
}

const dbMigration = async (credential: UserCredential) => {
  const { user } = credential;
  if (!user) return;

  const token = await user?.getIdToken();

  if (!token?.length) return;

  Axios({
    method: 'POST',
    url: `${process.env.apiUrlV2}/auth/umg`,
    headers: {
      'x-migration-token': 'VGhpc0lzQU1pZ3JhdGlvblRva2Vu'
    },
    data: {
      token
    }
  })
}

export const migrationFlow = ({ email, password }: { email: string, password: string }): any => {
  return signUp({ email, password })
    .then((credential: UserCredential) => dbMigration(credential))
    .catch(e => console.log(e));
}