
import { Component, Vue } from "nuxt-property-decorator";
import OrganizationNavbar from "~/components/organization/organization-navbar.vue";
// import Footer from '../components/global/footer.vue';
import OrganizationNavigationDrawer from "~/components/organization/organization-navigation-drawer.vue";

@Component({
  name: "Organization",
  components: {
    // footerComponent: Footer,
    organizationNavigationDrawer: OrganizationNavigationDrawer,
    organizationNavbar: OrganizationNavbar
  },
})
export default class Organization extends Vue {
  public openDrawer(): void {
    //@ts-ignore
    this.$refs['navigationDrawer']?.opened = true;
  }
  //@ts-ignore
  // fetch({ store, redirect }) {
  //   return store
  //     .dispatch("verifyUser")
  //     .then(() => {
  //       return;
  //     })
  //     .catch((error) => {
  //       redirect("/login");
  //     });
  // }

  mounted() {
    return this.$store.dispatch('verifyUser').then(() => {
      return;
    }).catch(error => {})
  }
}
