
import { Component, Prop, Vue } from "nuxt-property-decorator";

@Component({
  name: "OrganizationNavigationDrawer",
})
export default class OrganizationNavigationDrawer extends Vue {
  public opened: boolean = false;
  public loading: boolean = false;
  public get isDesktop(): boolean {
    //@ts-ignore
    return this.$vuetify.breakpoint.smAndUp;
  }

  public handleDrawer(event: boolean): void {
    this.$emit("transitionend", event);
  }

  public signOut(): void {
    this.loading = true;
    this.$store
      .dispatch("signOut")
      .then((response) => {
        if (typeof window !== "undefined") {
          window.location.pathname = "/";
        }
      })
      .catch((error) => {
        //@ts-ignore
        this.$notify({
          type: "error",
          message: "Logout fehlgeschlagen",
        });
      });
  }

  public pages: any[] = [
    {
      title: "Dashboard",
      link: "",
      icon: "mdi-home",
    },
    {
      title: "Finanzierungen",
      link: "offers",
      icon: "mdi-cash",
      children: [
        {
          title: "Neues Angebot",
          link: "/organization/offers/new-offer",
          icon: "mdi-plus",
        },
        {
          title: "Archiv",
          link: "/organization/offers/archive",
          icon: "mdi-archive-outline",
        },
      ],
    },
    {
      title: "Kontakte",
      link: "contacts",
      icon: "mdi-account-box-outline",
      children: [
        {
          title: "Neuer Kontakt",
          link: "/organization/contacts/new-contact",
          icon: "mdi-plus",
        },
        {
          title: "Archiv",
          link: "/organization/contacts/archive",
          icon: "mdi-archive-outline",
        },
      ],
    },
    {
      title: "Einstellungen",
      link: "settings",
      icon: "mdi-cog",
    },
  ];
}
