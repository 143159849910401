
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "Footer",
})
export default class Footer extends Vue {
  public get legalLinks(): any[] {
    return [
      {
        title: this.$t("Impressum"),
        link: "/imprint",
      },
      {
        title: this.$t("Datenschutz"),
        link: "/privacy",
      },
      {
        title: this.$t("AGB"),
        link: "/agb",
      },
      {
        title: this.$t("Kontakt"),
        link: "/contact",
      },
    ];
  }

  public get productLinks(): any[] {
    return [
      {
        title: this.$t("lender_search"),
        link: "/lenders",
      },
      {
        title: this.$t("signup"),
        link: "/signup",
      },
      {
        title: this.$t("login"),
        link: "/login",
      },
      {
        title: this.$t("for_lenders"),
        link: "/for-partners",
      },
      {
        title: this.$t("for_agents"),
        link: "/for-agents",
      },
      {
        title: this.$t("how_to"),
        link: "/how-to",
      },
      // {
      // 	title: 'Unsere Preise',
      // 	link: '/pricing'
      // },
    ];
  }

  public get companyLinks(): any[] {
    return [
      {
        title: this.$t("about"),
        link: "/about",
      },
      {
        title: this.$t("partner"),
        link: "/partner",
      },
      {
        title: this.$t("blog"),
        link: "/blog",
      },
      {
        title: this.$t("press"),
        link: "/press",
      },
      {
        title: this.$t("jobs"),
        link: "/jobs",
      },
    ];
  }

  public payments: any[] = [
    { title: "Mastercard", icon: require("~/assets/images/payment/mastercard.png") },
    { title: "Visa", icon: require("~/assets/images/payment/visa.png") },
    { title: "American Express", icon: require("~/assets/images/payment/amex.png") },
    { title: "SEPA", icon: require("~/assets/images/payment/sepa.png") },
  ];

  public get copyYear(): string {
    return String(new Date().getFullYear());
  }
}
