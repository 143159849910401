import Axios from 'axios';
import { QueryBuilder } from '~/plugins/QueryBuilder';

export const state = () => ({
  completeListSelected: true,
  leadCount: 0,
  selectedIds: []
})

export const mutations = {
  LIST_SELECT(state: any, event: boolean) {
    state.completeListSelected = event;
  },
  SET_IDS(state: any, payload: string[]) {
    state.selectedIds = [...new Set(payload)];
  },
  REMOVE_LENDER(state: any, lenderId: string) {
    const index = state.selectedIds.findIndex((l: string) => l === lenderId);
    state.selectedIds.splice(index, 1);
  }
}

export const actions = {
  async subscribeToPremium(context: any, product: "basic" | "premium") {
    const session = await Axios({
      method: 'POST',
      url: `${process.env.apiUrl}/purchases/subscribe-premium`,
      data: {product: product}
    }).then(response => response.data);

    return session;
  },

  async openCustomerPortal(context: any) {
    const portalUrl = await Axios({
      method: 'POST',
      url: `${process.env.apiUrl}/invoice-portal`,
      data: {}
    }).then(response => response.data);

    return portalUrl;
  },

  async confirmAccountUpgrade(context: any, sessionId: string) {
    const confirmed = await Axios({
      method: 'GET',
      url: `${process.env.apiUrl}/upgrade/confirmation?session=${sessionId}`
    }).then(response => response.data);

    return confirmed;
  }
}
