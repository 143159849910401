import Axios from "axios";
import { IProjectOutcome, note } from "../model/outcome";

export interface IOutcomeState {
  projectOutcomes: IProjectOutcome[]
}


export const state = (): IOutcomeState => ({
  projectOutcomes: []
});

export const mutations = {
  SET_PROJECT_OUTCOMES(state: IOutcomeState, payload: IProjectOutcome[]) {
    state.projectOutcomes = payload;
  },
  SET_SINGLE_PROJECT_OUTCOME(state: IOutcomeState, payload: IProjectOutcome) {
    const outcomeIndex = state.projectOutcomes?.findIndex(outcome => outcome?.id === payload?.id);
    state.projectOutcomes?.splice(outcomeIndex, 1, payload);
  }
}

export const actions = {
  async fetchProjectOutcomes(context: any) {
    const projectOutcomes: IProjectOutcome = await Axios({
      method: 'GET',
      url: `${process.env.apiUrlV2}/project-outcome`
    })
      .then(response => {
        const { data } = response;
        context.commit('SET_PROJECT_OUTCOMES', data);
        return data;
      })
      .catch(e => {
        throw e;
      });

    return projectOutcomes;
  },

  async fetchProjectOutcomesByProjectId(context: any, projectId: string) {
    const projectOutcomes: IProjectOutcome = await Axios({
      method: 'GET',
      url: `${process.env.apiUrlV2}/project-outcome/${projectId}`
    })
      .then(response => {
        const { data } = response;
        context.commit('SET_PROJECT_OUTCOMES', data);
        return data;
      })
      .catch(e => {
        throw e;
      });

    return projectOutcomes;
  },

  async changeOutcomeStatus(context: any, { outcomeId, status }: { outcomeId: string, status: string }) {
    const savedOutcome = await Axios({
      method: 'PATCH',
      url: `${process.env.apiUrlV2}/project-outcome/update/${outcomeId}`,
      data: {
        status: status
      }
    })
      .then(response => {
        const { data } = response;
        context.commit('SET_SINGLE_PROJECT_OUTCOME', data);
        return data;
      })
      .catch(e => {
        throw e;
      });

    return savedOutcome;
  },

  async editOutcomeNotes(context: any, { outcomeId, notes }: { outcomeId: string, notes: note[] }) {
    const savedOutcome = await Axios({
      method: 'PATCH',
      url: `${process.env.apiUrlV2}/project-outcome/update/${outcomeId}`,
      data: {
        notes: notes
      }
    })
      .then(async (response) => {
        const { data } = response;
        await context.dispatch('fetchProjectOutcomes')
        return data;
      })
      .catch(e => {
        throw e;
      });

    return savedOutcome;
  },

  async editOutcome(context: any, { outcomeId, status, invitationName, createdAt }: { outcomeId?: string, status?: string, invitationName?: string, createdAt?: number | string }) {
    const updatedOutcome = await Axios({
      method: 'PATCH',
      url: `${process.env.apiUrlV2}/project-outcome/update/${outcomeId}`,
      data: {
        status,
        invitationName,
        createdAt
      }
    }).then(async (response) => {
      const { data } = response;
      await context.dispatch('fetchProjectOutcomes');
      return data;
    })
      .catch(e => { throw e; });
      return updatedOutcome;
  }
}