import Axios from 'axios';

export interface ContentInput {
  key: string;
  content: any;
}

export const state = () => ({
  content: {
    'about-us': {},
    'story': {},
    'organization-page': {},
    'customer-faqs': {},
    'public-faqs': {},
    'how-to': {},
    'agb': {},
    'display-features': []
  }
});

export const mutations = {
  SET_CONTENT(state: any, payload: ContentInput) {
    state.content[payload.key] = payload.content;
  }
}

export const actions = {
  async fetchContent(context: any, contentKey: string) {
    try {
      const content = await Axios({
        method: 'GET',
        url: `${process.env.apiUrl}/${contentKey}`
      }).then(response => response.data)
        .catch(error => {
          //@ts-ignore
          // this.app.router.push('/error');
          throw error;
        });

      if (!!content) await context.commit('SET_CONTENT', {
        content: content,
        key: contentKey
      });
    } catch (err) {
      console.error(err);
    }
  }
}
