import Axios from 'axios';

export const state = () => ({
  countries: [
    { key: 'de', text: 'Deutschland' },
    { key: 'at', text: 'Österreich' },
    { key: 'ch', text: 'Schweiz' },
  ],
  administrations: [],
  cities: []
});

export const mutations = {
  SET_CITIES(state: any, payload: any[]) {
    const cities = payload?.filter((hit: any) => {
      return !!hit?.is_city
        && !!hit.locale_names['default']?.length
        && !!hit.administrative?.length
    }).map((city: any) => {
      return {
        name: city.locale_names['default'][0],
        administration: city.administrative[0],
        text: `${city.locale_names['default'][0]} (${city.administrative[0]})`,
        key: `${city.locale_names['default'][0]}_${city.administrative[0]}_${city.country_code}`
      }
    })

    state.cities = cities;
  },
  SET_CITIES_INCLUDING_POSTCODE(state: any, payload: any[]) {
    const cities = payload?.filter((hit: any) => {
      return !!hit?.is_city
        && !!hit.locale_names['default']?.length
        && !!hit.administrative?.length
    })?.map((city: any) => {
      return {
        name: city.locale_names['default'][0],
        administration: city.administrative[0],
        text: `${city.locale_names['default'][0]} (${city.administrative[0]})`,
        zipCode: city.zipCode,
        key: `${city.locale_names['default'][0]}_${city.administrative[0]}_${city.country_code}`
      }
    });

    state.cities = cities;
  }
}

export const actions = {
  async fetchCities(context: any, input: any) {
    const { country, query } = input;
    const searchQuery: any = {
      type: 'city',
      query: query,
      apiKey: 'b91c8b3eda55dee0a9e23cd5173e9a64',
      countries: ['de', 'at', 'ch']
    }

    if (!!country) {
      searchQuery.countries = [country];
    }
    const cities = await Axios({
      method: 'POST',
      url: `${process.env.apiUrl}/locations/fetch`,
      data: {
        query: query
      }
    }).then(response => response.data)
      .catch(error => console.log(error))

    await context.commit('SET_CITIES', cities?.hits)
  },

  async fetchCitiesByZipCode(context: any, input: any) {
    const { country, query } = input;
    const searchQuery: any = {
      type: 'city',
      query: "",
      apiKey: 'b91c8b3eda55dee0a9e23cd5173e9a64',
      countries: ['de', 'at', 'ch'],
      restrictSearchableAttributes: "postcode"
    }

    if (!!country) {
      searchQuery.countries = [country];
    }
    let cities = await Axios({
      method: 'POST',
      url: `https://places-dsn.algolia.net/1/places/query/`,
      data: {
        ...searchQuery
      },
      headers: {
        'X-Algolia-Application-Id': 'pl821CFUM9RZ',
        'X-Algolia-API-Key': 'b91c8b3eda55dee0a9e23cd5173e9a64'
      }
    }).then(response => response.data)
      .catch(error => console.log(error))

      if (!!cities?.hits?.length) {
        cities.hits = cities.hits.map((city: any) => {
          city.zipCode = query;
          return city;
        })
      }


    await context.commit('SET_CITIES_INCLUDING_POSTCODE', cities?.hits)
  }
}
