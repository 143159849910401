<template>
	<v-app light>
		<div class="flex">
			<div>
				<h1 class="primary--text">404</h1>
				<h2 class="primary--text">{{$t('Not found')}}</h2>
				<nuxt-link to="/">{{$t('Go back to home page')}}</nuxt-link>
			</div>
		</div>
	</v-app>
</template>

<script>
export default {
	layout: "default",
	props: {
		error: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			pageNotFound: "404 - Nicht gefunden",
			otherError: "Es ist ein Fehler aufgetreten",
		};
	},
	head() {
		const title =
			this.error.statusCode === 404 ? this.pageNotFound : this.otherError;
		return {
			title,
		};
	},
};
</script>

<style scoped>
.flex {
	background: #f1f3f9;
	height: 100%;
	width: 100vw;
	justify-content: center;
	align-items: center;
	text-align: center;
	display: flex;
}
h1 {
	font-size: 10rem !important;
	@media screen and (max-width: 700px) {
		h1 {
			font-size: 5rem !important;
		}
	}
}
</style>
