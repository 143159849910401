
import { Component, Vue } from "vue-property-decorator";

@Component
export default class AnnouncementBanner extends Vue {
  public bannerClosed: boolean = false;

  public get isOnReportPage(): boolean {
    return this.$route?.path?.includes("reports");
  }

  public get isOnRegistrationSuccess(): boolean {
    return this.$route?.path?.includes("customer-registration") || this.$route?.path?.includes("registration-success") || this.$route?.path?.includes("login") || this.$route?.path?.includes("signup");
  }

  public get isOnProjectPage(): boolean {
    return this.$route?.path?.includes("projects") || this.$route?.path?.includes("project");
  }

  public get isOnLenderSearch(): boolean {
    return this.$route?.path?.includes("lenders");
  }

  public get isOnChristmasPage(): boolean {
    return this.$route?.path?.includes("x-mas");
  }

  public get showBanner(): boolean {
    return !this.bannerClosed && !this.isOnReportPage && !this.isOnProjectPage && !this.isOnRegistrationSuccess && !this.isOnLenderSearch && !this.isOnChristmasPage;
  }

  public closeBanner() {
    this.bannerClosed = true;
  }
}
