import Axios from 'axios';
import { BooleanFieldInput, FieldInput } from '~/model/search';
import { QueryBuilder } from '~/plugins/QueryBuilder';
import { StorageSync } from '~/plugins/storage-sync';
const sync = new StorageSync(this, false);

export const state = () => ({
  offerTypes: "",
  typeOf: "",
  amount: 0,
  minDuration: null,
  maxDuration: null,
  permissionStatus: null,
  financingPlan: null,
  requiredCapital: null,
  possibleSecuritization: null,
  financingStructure: [],
  crossUsage: null,
  location: '',
  lenderType: ["Bank", "Fonds", "Pensionskasse"],
  consortium: true
});

export const mutations = {
  SET_FIELD(state: any, input: FieldInput) {
    state[input.key] = input.value;
    sync.setLastUncompletedSearch(state);
  },
  SET_BOOLEAN_FIELD(state: any, input: BooleanFieldInput) {
    if (!!input.value) {
      // state.filters?.splice(state.filters.indexOf(input.options[1]));
      // state.filters.push(input.options[0]);
      sync.setLastUncompletedSearch(state);
    } else if (!input.value) {
      // state.filters?.splice(state.filters.indexOf(input.options[0]));
      // state.filters.push(input.options[1]);
      sync.setLastUncompletedSearch(state);
    }
  },
  SET_RESULTS(state: any, results: any) {
    state.results.partners = results.partners;
    state.results.offers = results.offers;
    state.results.nullSearchId = results.nullSearchId;
    state.results.listedResults = results.listedResults;
  },
  SET_COMPLETE_SEARCH(state: any, payload: any) {
    Object.keys(payload).forEach((key) => {
      if (key === 'amount') {
        return state['amount'] = +payload.amount;
      }

      return state[key] = payload[key];
    })
  },
  RESET_FILTERS(state: any, payload: any = null) {
    state = Object.assign(state, {
      offerTypes: "",
      typeOf: "",
      amount: 0,
      minDuration: null,
      maxDuration: null,
      permissionStatus: null,
      financingPlan: null,
      requiredCapital: null,
      possibleSecuritization: null,
      financingStructure: [],
      location: '',
      crossUsage: null,
      lenderType: ["Bank", "Fonds", "Pensionskasse"],
      consortium: true
    })
  },
  BULK_EDIT_FILTERS(state: any, payload: any) {
    Object.assign(state, payload);
  }
}

export const actions = {
  async searchFinancing(context: any, params: any) {
    const query = QueryBuilder.query(params);
    const results = await Axios({
      method: 'GET',
      url: `${process.env.apiUrl}/offers/search?${query}`
    }).then(response => response.data)
      .catch(error => { return { partners: 0, offers: 0 } });


    // sync.deleteLastSearchWhenCompleted();


    await context.commit('SET_RESULTS', results);
    return results;
  },

  async openLastSearchModal(context: any) {
    if (!!sync.shouldShowModal) {
      return true;
    } else {
      return false;
    }
  },

  async saveLastSearch(context: any) {
    sync.setLastUncompletedSearch(context.state);
  },
  async deleteLastSearch(context: any) {
    sync.deleteLastSearchWhenCompleted();
  },
  async continueSearch(context: any) {
    await context.commit('SET_COMPLETE_SEARCH', JSON.parse(sync.lastSearch));
    return true;
  },
  async enableNullSearchNotification(context: any, email: string) {
    const updated = await Axios({
      method: 'PATCH',
      url: `${process.env.apiUrl}/null-searches/${context?.state?.results?.nullSearchId}`,
      data: { email: email }
    }).then(response => response.data)
      .catch(error => { throw error })
  }
}
