import Axios from "axios"
import { IProject, IProjectUpdateOptions } from 'model/project';
import Vue from 'vue';

export interface IProjectState {
  projects: IProject[],
  project: IProject,
  newProject: IProject
  financingCheckResults: IFinancingCheckResults,
}

export interface IFinancingCheckResults {
  amounts: { min: number, max: number };
  count: number;
  durations: { min: number, max: number; }
  interestRates: { min: number, max: number };
  loanValues: { ltv: { min: number, max: number }, ltc: { min: number, max: number } };
  loans: { mezzanine: number, seniorLoan: number, wholeLoan: number };
  types: { Bank: number, Fonds: number, Pensionskasse: number };
}

export const state = (): IProjectState => ({
  projects: [],
  project: {} as IProject,
  newProject: {} as IProject,
  financingCheckResults: {
    amounts: { min: 0, max: 0 },
    count: 0,
    durations: { min: 0, max: 0 },
    interestRates: { min: 0, max: 0 },
    loanValues: { ltv: { min: 0, max: 0 }, ltc: { min: 0, max: 0 } },
    loans: { mezzanine: 0, seniorLoan: 0, wholeLoan: 0 },
    types: { Bank: 0, Pensionskasse: 0, Fonds: 0 }
  } as IFinancingCheckResults
})

const convertLocationToKey = (project: IProject) => {
  if (!!project?.location?.key && !!project?.location?.text) {
    return project.location.key;
  } else {
    return project?.location
  }
}

export const mutations = {
  SET_PROJECTS(state: any, projects: IProject[]) {
    state.projects = projects;
  },
  SET_ACTIVE_PROJECT(state: any, project: IProject) {
    state.project = project
  },
  UPDATE_PROJECT(state: any, value: IProjectUpdateOptions) {
    state.project[value.field] = value.value;
    Vue.set(state.project, value.field, value.value);

    if (!!state.project?.totalProjectVolume && !!state.project?.totalProjectVolume && !state.project._id) {
      state.project.ltv = (state.project?.totalRequiredCapital / state.project?.totalProjectVolume) * 100;
      state.project.ltc = (state.project?.totalRequiredCapital / state.project?.totalProjectVolume) * 100;
      Vue.set(state.project, 'ltv', +((state.project?.totalRequiredCapital / state.project?.totalProjectVolume) * 100).toFixed(2));
      Vue.set(state.project, 'ltc', +((state.project?.totalRequiredCapital / state.project?.totalProjectVolume) * 100).toFixed(2));
    }
  },

  RESET_PROJECT(state: any) {
    state.project = {} as IProject;
  },

  SPLICE_IMAGE(state: any, id: string) {
    const index = state.project.images.findIndex(i => i.id === id);
    state.project.images.splice(index, 1, null);
  },

  SET_FINANCING_CHECK_RESULTS(state: any, payload: any) {
    Object.assign(state.financingCheckResults, payload?.data)
  }
}

export const getters = {
  projectFields(state: any) {
    return Object.keys(state.project);
  }
}

export const actions = {
  async fetchProjects(context: any) {
    const projects: IProject[] = await Axios({
      method: 'GET',
      url: `${process.env.apiUrl}/projects`
    }).then(response => response.data)
      .catch(err => { throw err });

    await context.commit('SET_PROJECTS', projects);
  },

  async updateProject(context: any, payload: IProjectUpdateOptions) {
    if (payload?.field === 'location' && !!payload?.value?.key) {
      payload.value = payload.value.key;
    }
    context.commit('UPDATE_PROJECT', payload);

    if (!!context.state.project._id) {

      const updatedProject = await Axios({
        method: 'PATCH',
        url: `${process.env.apiUrl}/projects/${context.state.project._id}`,
        data: {
          ...context.state.project,
          list: (() => {
            if (!!context.state.project?.list?._id) {
              return context.state.project?.list?._id
            }
          })()
        }
      }).then(response => response.data)
        .catch(err => { throw err });

      await context.commit('SET_ACTIVE_PROJECT', updatedProject);
      await context.dispatch('optimization/fetchRecommendations', updatedProject._id, { root: true })
    } else {
      // context.commit('SET_ACTIVE_PROJECT', context.state.project);
    }
  },

  async addProject(context: any, project: IProject) {
    if (!project.requiredDocuments) {
      project.requiredDocuments = [];
    }

    if (!project.possibleSecuritization) {
      project.possibleSecuritization = [];
    }

    const _project = await Axios({
      method: 'POST',
      url: `${process.env.apiUrl}/projects`,
      data: {
        ...project,
        location: convertLocationToKey(project)
      }
    }).then(response => response.data)
      .catch(e => { throw e });

    return _project;
  },

  async selectContact(context: any, contactId: any) {
    const contacts = context.state.project?.preparedContacts?.map((contact: any) => {
      return contact._id;
    });

    contacts.push(contactId);

    if (!!context.state.project._id) {
      const updatedProject = await Axios({
        method: 'PATCH',
        url: `${process.env.apiUrl}/projects/${context.state.project._id}`,
        data: {
          ...context.state.project,
          preparedContacts: contacts,
          list: (() => {
            if (!!context.state.project?.list?._id) {
              return context.state.project?.list?._id
            }
          })()
        }
      }).then(response => response.data)
        .catch(err => { throw err });

      await context.commit('SET_ACTIVE_PROJECT', updatedProject);
    } else {
      // context.commit('SET_ACTIVE_PROJECT', context.state.project);
    }
  },

  async removeContact(context: any, contactId: any) {
    const contacts = context.state.project?.preparedContacts?.map((contact: any) => {
      return contact._id;
    });

    const contactIndex = contacts.findIndex((c: any) => c === contactId);

    contacts.splice(contactIndex, 1);

    if (!!context.state.project._id) {
      const updatedProject = await Axios({
        method: 'PATCH',
        url: `${process.env.apiUrl}/projects/${context.state.project._id}`,
        data: {
          ...context.state.project,
          preparedContacts: contacts,
          list: (() => {
            if (!!context.state.project?.list?._id) {
              return context.state.project?.list?._id
            }
          })()
        }
      }).then(response => response.data)
        .catch(err => { throw err });

      await context.commit('SET_ACTIVE_PROJECT', updatedProject);
    } else {
      // context.commit('SET_ACTIVE_PROJECT', context.state.project);
    }
  },

  async sendProjectMail(context: any) {
    const mailSent = await Axios({
      method: 'POST',
      url: `${process.env.apiUrl}/projects/send/${context.state.project._id}`,
      data: {
        contacts: context.state.project.preparedContacts
      }
    }).then(response => { })
      .catch(err => { throw err });
  },

  async sendPreviewMail(context: any) {
    const mailSent = await Axios({
      method: 'POST',
      url: `${process.env.apiUrl}/projects/preview/${context.state.project._id}`,
      data: {}
    }).then(response => { })
      .catch(err => { throw err });
  },

  async deleteProject(context: any, projectId: string) {
    const delted = await Axios({
      method: 'DELETE',
      url: `${process.env.apiUrl}/projects/${projectId}`
    }).then(response => response.data)
      .catch(err => { throw err });
  },

  convertProjectToSearchQuery(context: any, project?: any): any {
    const { typeOf, usageType, monetarization, permissionStatus, totalRequiredCapital, requiredCapital, minDuration, maxDuration, location } = context.state.project;
    const query = {};


    // Set boolean attributes included in query
    // [typeOf, usageType, monetarization, permissionStatus, requiredCapital].forEach((value: any) => {
    //   if (!!value) {
    //     query[value] = true;
    //   }
    // });

    // query['location'] = location;
    // query['amount'] = totalRequiredCapital;
    // query['duration'] = duration;

    query['location'] = location;
    query['amount'] = totalRequiredCapital;
    query['minDuration'] = String(minDuration);
    query['maxDuration'] = String(maxDuration);
    query['offerTypes'] = usageType;
    query['typeOf'] = typeOf;
    query['permissionStatus'] = permissionStatus;
    query['financingPlan'] = monetarization;
    query['requiredCapital'] = requiredCapital;

    return query;
  },

  async uploadImage(context: any, image: any) {
    // helper function: generate a new file from base64 String
    const dataURLtoFile = (dataurl, filename) => {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1)
        n -= 1 // to make eslint happy
      }
      return new File([u8arr], filename, { type: mime })
    }

    // generate file from base64 string
    // const file = dataURLtoFile(image, `${context.state.project._id}_${Date.now()}.jpeg`)

    const file = image;


    // put file into form data
    const formData = new FormData();
    const data: any = context.state.project;
    const name = 'images';

    formData.append(`files.images`, file, file.name);
    formData.append('data', JSON.stringify(data));

    // now upload
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
    const uploaded = await Axios.patch(`${process.env.apiUrl}/projects/${context.state.project._id}`, formData, config).then(response => {
      return response.data;
    });

    await context.commit('SET_ACTIVE_PROJECT', uploaded);

    return uploaded;
  },

  async deleteImage(context: any, id: string) {
    context.commit('SPLICE_IMAGE', id);
    context.dispatch('updateProject', {})
  },

  async financingCheck({ commit }, projectId) {
    const financingCheckResult = await Axios({
      method: 'GET',
      url: `${process.env.apiUrlV2}/market-overview/projects/${projectId}`
    })
      .then(response => {
        commit('SET_FINANCING_CHECK_RESULTS', response.data);
        return response.data;
      })
      .catch(e => { throw e });

    return financingCheckResult;
  },

  async copyProject({ commit }: any, id: string) {
    const newProject = await Axios({
      method: 'POST',
      url: `${process.env.apiUrl}/projects/copy`,
      data: { id: id }
    }).then(response => response.data);

    return newProject;
  },

  async handleContact(context: any, data: any) {
    if (!data) throw new Error('Missing data.');
    console.log(data);

    context.commit('UPDATE_PROJECT', { field: 'invitations', value: data.invitedContacts });
    context.commit('UPDATE_PROJECT', { field: 'preparedContacts', value: data.preparedContacts });

    const updatedProject = await Axios({
      method: 'PATCH',
      url: `${process.env.apiUrl}/projects/${context.state.project._id}`,
      data: {
        invitations: data.invitedContacts,
        preparedContacts: data.preparedContacts
      }
    }).then(response => response.data)
      .catch(err => { throw err });

    await context.commit('SET_ACTIVE_PROJECT', updatedProject);

    return updatedProject;
  },

  async sendProjectToSingleFinListLender(context: any, { contactId, projectId }: any) {
    if (!contactId || !projectId) throw new Error('Data missing.');

    const sent = await Axios({
      method: 'POST',
      url: `${process.env.apiUrlV2}/projects/${projectId}/send`,
      data: {
        contactId: contactId
      }
    }).then(response => {
      const { data } = response;
      return data;
    })
      .catch(e => { throw e })

    return sent;
  }
}
