
import { Component, Vue, Watch } from "nuxt-property-decorator";
import UserState from "./user-state.vue";

@Component({
  name: "Navbar",
  components: {
    userState: UserState,
  },
})
export default class Navbar extends Vue {
  @Watch("$route")
  handler(value) {
    this.menuOpen = false;
  }

  public offset: number = 0;
  public completelyMounted: boolean = false;
  public menuOpen: boolean = false;
  public get isDesktop(): boolean {
    // @ts-ignore
    return this.$vuetify.breakpoint.lgAndUp as boolean;
  }

  public get isLoggedIn(): boolean {
    return !!this.$store.state.user?.email;
  }

  public get isOnLenderPage(): boolean {
    return this.$route.path.includes("/lenders");
  }

  mounted() {
    this.completelyMounted = true;
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        this.offset = window.pageYOffset;
      });
    }
  }

  public get pages(): any[] {
    return [
      {
        key: this.$t("lender_search"),
        link: "/lenders",
        icon: "mdi-account-search-outline",
      },
      {
        key: this.$t("how_to"),
        link: "/how-to",
        children: [
          {
            key: this.$t("for_borrowers"),
            link: "/how-to",
            icon: "mdi-help-circle-outline",
            description: this.$t("for_borrowers_description"),
          },
          {
            key: this.$t("for_lenders"),
            link: "/for-partners",
            icon: "mdi-bank",
            description: this.$t("for_partners_description"),
          },
          {
            key: this.$t("for_agents"),
            link: "/for-agents",
            icon: "mdi-handshake-outline",
            description: this.$t("for_agents_description"),
          },
        ],
      },
      {
        key: this.$t("about"),
        link: "/about",
        children: [
          {
            key: this.$t("about"),
            link: "/about",
            icon: "mdi-information-outline",
            description: this.$t("about_description"),
          },
          {
            key: "Blog",
            link: "/blog",
            icon: "mdi-post-outline",
            description: this.$t("blog_description"),
          },
          {
            key: this.$t("press"),
            link: "/press",
            icon: "mdi-newspaper-variant-outline",
            description: this.$t("press_description"),
          },
          {
            key: "Partner",
            link: "/partner",
            icon: "mdi-account-box-multiple-outline",
            description: this.$t("partner_description"),
          },
          {
            key: "Jobs",
            link: "/jobs",
            icon: "mdi-briefcase-outline",
            description: this.$t("job_description"),
          },
        ],
      },
      {
        key: this.$t("contact"),
        link: "/contact",
        icon: "mdi-account-search-outline",
      },
      // {
      //   key: "Preise",
      //   link: "/pricing",
      // 	icon: 'mdi-currency-eur'
      // },
    ];
  }
}
