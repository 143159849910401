import Axios from 'axios';
import Vue from 'vue';

const convertLocationToKey = (trackrecord: any) => {
  if (!!trackrecord?.location?.key && !!trackrecord?.location?.text) {
    return trackrecord.location.key;
  } else {
    return trackrecord?.location
  }
}

export const state = () => ({
  trackrecords: [],
  trackrecord: {},
  newTrackrecord: {}
});

export const mutations = {
  SET_TRACKRECORDS(state: any, trackrecords: any[]) {
    state.trackrecords = trackrecords;
  },
  SET_ACTIVE_TRACKRECORD(state: any, trackrecord: any) {
    state.trackrecord = trackrecord
  },
  UPDATE_TRACKRECORD(state: any, value: any) {
    state.trackrecord[value.field] = value.value;
    Vue.set(state.trackrecord, value.field, value.value);
  }
}

export const actions = {
  async fetchRecords(context: any) {
    const trackrecords: any[] = await Axios({
      method: 'GET',
      url: `${process.env.apiUrl}/trackrecords`
    }).then(response => response.data)
      .catch(err => { throw err });

    await context.commit('SET_TRACKRECORDS', trackrecords);
  },

  async updateTrackrecord(context: any, payload: any) {
    if (payload?.field === 'location' && !!payload?.value?.key) {
      payload.value = payload.value.key;
    }
    context.commit('UPDATE_TRACKRECORD', payload);

    if (!!context.state.trackrecord._id) {

      const updatedtrackrecord = await Axios({
        method: 'PATCH',
        url: `${process.env.apiUrl}/trackrecords/${context.state.trackrecord._id}`,
        data: {
          ...context.state.trackrecord
        }
      }).then(response => response.data)
        .catch(err => { throw err });

      await context.commit('SET_ACTIVE_TRACKRECORD', updatedtrackrecord);
    } else {
      // context.commit('SET_ACTIVE_TRACKRECORD', context.state.trackrecord);
    }
  },

  async addTrackrecord(context: any, trackrecord: any) {
    const _trackrecord = await Axios({
      method: 'POST',
      url: `${process.env.apiUrl}/trackrecords`,
      data: {
        ...trackrecord,
        location: convertLocationToKey(trackrecord)
      }
    }).then(response => response.data)
      .catch(e => { throw e });

    return _trackrecord;
  },
}
