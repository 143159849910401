import Axios from 'axios';

export const state = () => ({
  invoices: [],
  invoice: {},
  navigatedFromProject: false
});

export const mutations = {
  SET_INVOICES(state: any, payload: any[]) {
    state.invoices = payload;
  },
  SET_INVOICE(state: any, payload: any) {
    state.invoice = payload;
  },
  SET_PROJECT_NAVIGATION(state: any, payload: boolean) {
    state.navigatedFromProject = payload;
  }
}

export const actions = {
  async fetchCustomerDetails(context: any) {
    const customer = await Axios({
      method: 'GET',
      url: `${process.env.apiUrl}/customer-settings`
    })
      .then((response => response.data))
      .catch(error => { throw error });

    return await customer;
  },
  async updateCustomerDetails(context: any, customer: any) {
    const updated = await Axios({
      method: 'PATCH',
      url: `${process.env.apiUrl}/customer-settings`,
      data: customer
    })
      .then(response => true)
      .catch(error => { throw error })
  },
  async fetchInvoices(context: any) {
    const invoices = await Axios({
      method: 'GET',
      url: `${process.env.apiUrl}/invoices`
    })
      .then(response => response.data)
      .catch(error => { throw error });

      await context.commit('SET_INVOICES', invoices);
      return invoices;
  },
  async fetchInvoice(context: any, id: string | number) {
    const invoice = await Axios({
      method: 'GET',
      url: `${process.env.apiUrl}/invoices/${id}`
    })
      .then(response => response.data)
      .catch(error => { throw error });

      await context.commit('SET_INVOICE', invoice);
      return invoice;
  }
}
