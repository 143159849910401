import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_0651c174 from 'nuxt_plugin_sentryserver_0651c174' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_d1c4a484 from 'nuxt_plugin_sentryclient_d1c4a484' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_plugin_04efe702 from 'nuxt_plugin_plugin_04efe702' // Source: ./vuetify/plugin.js (mode: 'all')
import nuxt_plugin_pluginutils_594760f9 from 'nuxt_plugin_pluginutils_594760f9' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_4a18d7f8 from 'nuxt_plugin_pluginrouting_4a18d7f8' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_540975eb from 'nuxt_plugin_pluginmain_540975eb' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_templatesplugin420e5039_6ef90144 from 'nuxt_plugin_templatesplugin420e5039_6ef90144' // Source: ./templates.plugin.420e5039.js (mode: 'client')
import nuxt_plugin_pluginclient_20d349e8 from 'nuxt_plugin_pluginclient_20d349e8' // Source: ./content/plugin.client.js (mode: 'client')
import nuxt_plugin_pluginserver_f2e68920 from 'nuxt_plugin_pluginserver_f2e68920' // Source: ./content/plugin.server.js (mode: 'server')
import nuxt_plugin_metaplugin_1027319e from 'nuxt_plugin_metaplugin_1027319e' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_06d7e6b6 from 'nuxt_plugin_iconplugin_06d7e6b6' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_axios_11360182 from 'nuxt_plugin_axios_11360182' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_googleanalytics_1eacb164 from 'nuxt_plugin_googleanalytics_1eacb164' // Source: ./google-analytics.js (mode: 'client')
import nuxt_plugin_globals_13ba8314 from 'nuxt_plugin_globals_13ba8314' // Source: ../plugins/globals.ts (mode: 'all')
import nuxt_plugin_axios_54e49864 from 'nuxt_plugin_axios_54e49864' // Source: ../plugins/axios.ts (mode: 'all')
import nuxt_plugin_errorinterceptor_6a9715c3 from 'nuxt_plugin_errorinterceptor_6a9715c3' // Source: ../plugins/error-interceptor.ts (mode: 'all')
import nuxt_plugin_notify_3d9f46e4 from 'nuxt_plugin_notify_3d9f46e4' // Source: ../plugins/notify.ts (mode: 'all')
import nuxt_plugin_masonry_17bd1abe from 'nuxt_plugin_masonry_17bd1abe' // Source: ../plugins/masonry.ts (mode: 'client')
import nuxt_plugin_croppa_84503754 from 'nuxt_plugin_croppa_84503754' // Source: ../plugins/croppa.ts (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"titleTemplate":"%s","title":"Passende Kreditgeber für Ihre Immobilie","modern":"server","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Egal, ob Sie erfahrender Projektentwickler sind oder gerade erst anfangen. Bei FinList finden Sie den passenden Kreditgeber."},{"hid":"og:title","property":"og:title","name":"og:title","content":"Kreditsoftware für gewerbliche Immobilienfinanzierungen - FinList"},{"hid":"og:site_name","property":"og:site_name","name":"og:site_name","content":"FinList"},{"hid":"og:image","property":"og:image","name":"og:image","content":"https:\u002F\u002Ffinlist.de\u002Fog_image.png"},{"hid":"og:description","property":"og:description","name":"og:description","content":"Finlist gibt Projektentwicklern, Bauträgern, Investoren und ähnlichen Persona im deutschen und österreichischen Raum, Kreditgeberinformationen von den Kontaktdaten der relevanten Ansprechpartner bis hin zu Besonderheiten in der Finanzierung. Mit unserer Leadliste bekommen Sie für Ihr Projekt Aussagen zu Fremdkapitalgebern und Mezzanine."}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.png"},{"rel":"shortcut icon","type":"image\u002Fx-icon","href":"\u002Fios_logo.png"},{"rel":"apple-touch-icon","type":"image\u002Fx-icon","href":"\u002Fios_logo.png"}],"script":[],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_0651c174 === 'function') {
    await nuxt_plugin_sentryserver_0651c174(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_d1c4a484 === 'function') {
    await nuxt_plugin_sentryclient_d1c4a484(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_04efe702 === 'function') {
    await nuxt_plugin_plugin_04efe702(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_594760f9 === 'function') {
    await nuxt_plugin_pluginutils_594760f9(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_4a18d7f8 === 'function') {
    await nuxt_plugin_pluginrouting_4a18d7f8(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_540975eb === 'function') {
    await nuxt_plugin_pluginmain_540975eb(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_templatesplugin420e5039_6ef90144 === 'function') {
    await nuxt_plugin_templatesplugin420e5039_6ef90144(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pluginclient_20d349e8 === 'function') {
    await nuxt_plugin_pluginclient_20d349e8(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_pluginserver_f2e68920 === 'function') {
    await nuxt_plugin_pluginserver_f2e68920(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_1027319e === 'function') {
    await nuxt_plugin_metaplugin_1027319e(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_06d7e6b6 === 'function') {
    await nuxt_plugin_iconplugin_06d7e6b6(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_11360182 === 'function') {
    await nuxt_plugin_axios_11360182(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googleanalytics_1eacb164 === 'function') {
    await nuxt_plugin_googleanalytics_1eacb164(app.context, inject)
  }

  if (typeof nuxt_plugin_globals_13ba8314 === 'function') {
    await nuxt_plugin_globals_13ba8314(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_54e49864 === 'function') {
    await nuxt_plugin_axios_54e49864(app.context, inject)
  }

  if (typeof nuxt_plugin_errorinterceptor_6a9715c3 === 'function') {
    await nuxt_plugin_errorinterceptor_6a9715c3(app.context, inject)
  }

  if (typeof nuxt_plugin_notify_3d9f46e4 === 'function') {
    await nuxt_plugin_notify_3d9f46e4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_masonry_17bd1abe === 'function') {
    await nuxt_plugin_masonry_17bd1abe(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_croppa_84503754 === 'function') {
    await nuxt_plugin_croppa_84503754(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
