import Axios from 'axios';
import { BooleanFieldInput, FieldInput } from '~/model/search';
import { QueryBuilder } from '~/plugins/QueryBuilder';
import { StorageSync } from '~/plugins/storage-sync';
const sync = new StorageSync(this, false);

export const state = () => ({
  results: {}
});

export const mutations = {
  SET_FIELD(state: any, input: FieldInput) {
    state[input.key] = input.value;
    sync.setLastUncompletedSearch(state);
  },
  SET_BOOLEAN_FIELD(state: any, input: BooleanFieldInput) {
    if (!!input.value) {
      // state.filters?.splice(state.filters.indexOf(input.options[1]));
      // state.filters.push(input.options[0]);
      sync.setLastUncompletedSearch(state);
    } else if (!input.value) {
      // state.filters?.splice(state.filters.indexOf(input.options[0]));
      // state.filters.push(input.options[1]);
      sync.setLastUncompletedSearch(state);
    }
  },
  SET_RESULTS(state: any, results: any) {
    state.results.partners = results.partners;
    state.results.offers = results.offers;
    state.results.nullSearchId = results.nullSearchId;
    state.results.listedResults = results.listedResults;
  }
}

export const actions = {
  async searchFinancing(context: any, params: any) {
    const query = QueryBuilder.query(params);
    const results = await Axios({
      method: 'GET',
      url: `${process.env.apiUrl}/offers/search?${query}`
    }).then(response => response.data)
      .catch(error => { return { partners: 0, offers: 0 } });


    // sync.deleteLastSearchWhenCompleted();


    await context.commit('SET_RESULTS', results);
    return results;
  }
}
