import Axios from "axios";

// State holds the data
export const state = () => ({
  sentProjects: [],
  deliveriesByProject: []
})

// Mutations mutate the state
export const mutations = {
  SET_SENT_PROJECTS(state: any, sentProjects: any[]) {
    state.sentProjects = sentProjects;
  },
  SET_DELIVERIES_FOR_PROJECT(state: any, deliveries: any[]) {
    state.deliveriesByProject = deliveries ?? [];
  }
}

// Actions are asynchronous and load data from apis
export const actions = {
  async fetchSentProjects(context: any, userId: string) {
    const sentProjects = await Axios({
      method: 'GET',
      url: `${process.env.apiUrlV2}/delivery/${userId}`
    })
      .then((result) => {
        const sentProjects = result?.data;
        context.commit('SET_SENT_PROJECTS', sentProjects)
      })
      .catch((e) => { throw e });

    return sentProjects;
  },

  async fetchDeliveriesByProject(context: any, {userId, projectId}: any) {
    if (!projectId) throw new Error('Data missing.');

    const deliveries = await Axios({
      method: 'GET',
      url: `${process.env.apiUrlV2}/delivery/${userId}/${projectId}`
    }).then(response => {
      const { data } = response;
      context.commit('SET_DELIVERIES_FOR_PROJECT', data);
    })
      .catch(e => { throw e });

      return deliveries
  }
}