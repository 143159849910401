
import { Component, Vue } from "nuxt-property-decorator";

@Component({
  name: "CustomerNavbar",
})
export default class CustomerNavbar extends Vue {
  public loading: boolean = false;
  public get isDesktop(): boolean {
    // @ts-ignore
    return this.$vuetify.breakpoint.smAndUp;
  }

  public openDrawer(): void {
    this.$emit('drawerOpened');
  }

  public signOut(): void {
    this.loading = true;
    this.$store.dispatch('signOut').then(response => {
      if (typeof window !== 'undefined') {
        window.location.pathname = '/';
      }
    })
    .catch(error => {
      //@ts-ignore
      this.$notify({
        type: 'error',
        message: 'Logout fehlgeschlagen'
      })
    })
  }
}
