import Axios from 'axios';
import { IProject } from '~/model/project';

export interface IConsentInput {
  token: string;
  email: string;
  timestamp?: number;
}

export interface IManualConsentInput {
  project: IProject;
  email: string;
  timestamp?: number;
}

export const actions = {
  async submitConsent(context: any, input: IConsentInput) {
    const sent = await Axios({
      method: 'POST',
      url: `${process.env.apiUrl}/projects/submit-consent`,
      data: input
    }).then(response => response.data)
      .catch(err => { throw err });

    if (!!sent?._id) {
      return sent._id;
    } else { throw 'NOT_FOUND' };
  },

  async submitManualConsent(context: any, input: IManualConsentInput) {
    const sent = await Axios({
      method: 'POST',
      url: `${process.env.apiUrl}/projects/submit-consent/manual`,
      data: input
    }).then(response => response.data)
      .catch(err => { throw err });

    if (!!sent?._id) {
      return sent._id;
    } else { throw 'NOT_FOUND' };
  },

  async findBuyers(context: any, project: IProject) {
    const buyerCount = await Axios({
      method: 'POST',
      url: `${process.env.apiUrl}/projects/find-buyers`,
      data: { project: project }
    }).then(response => response.data)
      .catch(err => { throw err });

      return buyerCount;
  }

}
