
import { Component, Vue } from "nuxt-property-decorator";
import CustomerNavbar from "~/components/customer/customer-navbar.vue";
// import Footer from '../components/global/footer.vue';
// import CustomerNavigationDrawer from "~/components/customer/customer-navigation-drawer.vue";
import AccountManager from "../components/customer/account-manager.vue";

@Component({
  name: "Customer",
  components: {
    // footerComponent: Footer,
    // customerNavigationDrawer: CustomerNavigationDrawer,
    customerNavbar: CustomerNavbar,
    accountManager: AccountManager,
  },
  head: () => {
    return {
      link: [
        { rel: "stylesheet", href: "/icon-fonts/mdi/css/materialdesignicons.min.css" },
        { rel: "stylesheet", href: "https://cdnjs.cloudflare.com/ajax/libs/quill/1.3.7/quill.snow.min.css" },
        { rel: "stylesheet", href: "https://cdnjs.cloudflare.com/ajax/libs/quill/1.3.7/quill.core.css" },
      ],
    };
  },
})
export default class Customer extends Vue {
  public dialogOpen: boolean = false;
  public signUpDialogOpen: boolean = false;
  public supportOpen: boolean = false;

  public get isDesktop(): boolean {
    return this.$vuetify.breakpoint.smAndUp ?? false;
  }

  public get user() {
    return this.$store?.state?.user;
  }

  public get profileIsComplete(): boolean {
    const _user = this.$store.state.user;

    return !!_user?.fullName && _user?.email && _user?.company && _user?.company?.title && _user?.company?.address && _user?.company?.address?.street && _user?.company?.address?.streetNumber && _user?.company?.address?.zipCode && _user?.company?.address?.city;
  }

  public goToSettings(): void {
    this.dialogOpen = false;
    this.$router.push("/customer/settings");
  }

  public get comesFromSignup(): boolean {
    return !!this.$route?.query?.signup;
  }

  public openDrawer(): void {
    //@ts-ignore
    this.$refs["navigationDrawer"]?.opened = true;
  }

  //@ts-ignore
  // fetch({ store, redirect }) {
  //   return store
  //     .dispatch("verifyUser")
  //     .then(() => {
  //       return;
  //     })
  //     .catch((error) => {
  //       redirect("/login");
  //     });
  // }

  public get clippedRight(): boolean {
    const conditions = [!this.$route.path?.includes("projects/edit"), this.$route.path?.includes("projects") && !this.$route.path?.includes("projects/edit") && !!this.$route.params.id];

    return conditions.some(Boolean);
  }

  public openSupport() {
    this.supportOpen = !this.supportOpen;
  }

  mounted() {
    return this.$store
      .dispatch("verifyUser")
      .then(() => {
        if (!this.profileIsComplete && !this.comesFromSignup) {
          this.dialogOpen = true;
        }

        if (this.comesFromSignup) {
          this.signUpDialogOpen = true;
        }
      })
      .catch((error) => {});
  }
}
